import { IsDialogMixin } from "@bakerware/vue-dialogs";
import Component from "vue-class-component";
import html from './reject-claim.dialog.html';
import {Observable, type VForm} from "@bakerware/vue-utils";
import {Inject, Prop, Ref} from "vue-property-decorator";
import type { Claim, RejectClaimRequest } from "@ncnp-mono/generics";
import {ClaimService} from "@ncnp-mono/utils";
import {required, hasNoScriptTags} from "@bakerware/vue-validation-rules";
import { ClaimStateEnum, StateClaimRequest } from "@ncnp-mono/generics";

@Component({
    template: html,
})
export class RejectClaimDialog extends IsDialogMixin {

    @Prop()
    claim!: Claim;

    @Inject()
    claimService!: ClaimService;

    @Ref()
    public form!: VForm;

    public result = new Observable<boolean>();
    public rules = {
        reasonNotAuthorized: [required, hasNoScriptTags],
    };
    public stateClaimRequest: StateClaimRequest = {
        state: ClaimStateEnum.REJECTCLAIM
    }
    public rejectClaimRequest: RejectClaimRequest = {
        ...this.stateClaimRequest,
        reasonNotAuthorized: ''
    };

    public async submit(): Promise<void> {
        if (!this.form.validate()) {
            return;
        }

        try {
            await this.claimService.updateClaim(this.claim.id, this.rejectClaimRequest);

            this.result.emit(true);

            this.$snackbar.success('De vorderingsregel is ingetrokken');

            this.activeDialog.close();
        } catch(e) {
            this.$snackbar.danger('Er is een fout opgetreden bij het intrekken van de vorderingsregel');
        }
    }
}
