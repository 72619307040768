import {DataTableHeader} from "vuetify";

export const OrganisationUsersTableHeaders: DataTableHeader[] = [
    {
        text: '',
        value: 'avatar',
        sortable: false,
    },
    {
        text: 'Naam',
        value: 'name',
    },
    {
        text: 'E-mail',
        value: 'email',
        sortable: false,
    },
    {
        text: 'Gebruikersbeheer',
        value: 'rightUserManagement',
        sortable: false,
    },
    {
        text: '',
        value: 'edit',
        sortable: false,
    },
];
