import Vue from 'vue';
import Component from 'vue-class-component';
import html from './action-documents-table.component.html';
import { formatDate } from '@ncnp-mono/utils';
import { Prop } from 'vue-property-decorator';
import { Action } from '@ncnp-mono/generics';
import { DataTableHeader } from 'vuetify';
import { ActionDocumentsOverviewHeaders } from '../../config';

@Component({
    template: html,
    filters: {
        date: formatDate,
    },
})
export class ActionDocumentsTableComponent extends Vue {
    @Prop({
        default: (): Action[] => [],
    })
    public actions!: Action[];

    @Prop({
        default: () => false,
    })
    public hasDownload!: boolean;

    public actionDocumentsOverviewHeaders: DataTableHeader[] = ActionDocumentsOverviewHeaders;

    public downloadFile(id: string, name: string): void {
        this.$emit('downloadFile', {
            id: id,
            name: name,
        });
    }
}
