import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { TaxOfficeUserRequest, User } from '@ncnp-mono/generics';

@Register()
export class TaxOfficeUserService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public getTaxOfficeUsers(taxOfficeId: number): Promise<User[]> {
        return this.httpService.get(`api/v1/tax-office-users/${taxOfficeId}`);
    }

    public createTaxOfficeUserById(taxOfficeUserRequest: TaxOfficeUserRequest, taxOfficeId: number): Promise<User> {
        return this.httpService.post(`api/v1/tax-office-users/${taxOfficeId}`, taxOfficeUserRequest);
    }

    public updateTaxOfficeUser(taxOfficeUserId: number, taxOfficeUserRequest: TaxOfficeUserRequest): Promise<User> {
        return this.httpService.put(`api/v1/tax-office-users/${taxOfficeUserId}`, taxOfficeUserRequest);
    }
}
