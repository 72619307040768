import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { BackendType,
    ReasonObjection,
    TaxOffice,
    TaxOfficeCreateRequest,
    TaxOfficeUpdateRequest } from '@ncnp-mono/generics';

@Register()
export class TaxOfficeService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public getTaxOffices(): Promise<TaxOffice[]> {
        return this.httpService.get('api/v1/tax-offices');
    }

    public getTaxOffice(taxOfficeId: number): Promise<TaxOffice> {
        return this.httpService.get(`api/v1/tax-offices/${taxOfficeId}`);
    }

    public getTaxOfficeForUser(): Promise<TaxOffice> {
        return this.httpService.get('api/v1/tax-offices/tax-office-for-user');
    }

    public createTaxOffice(taxOfficeCreateRequest: TaxOfficeCreateRequest): Promise<TaxOffice> {
        return this.httpService.post('api/v1/tax-offices', taxOfficeCreateRequest);
    }

    public updateTaxOffice(taxOfficeId: number, taxOfficeUpdateRequest: TaxOfficeUpdateRequest): Promise<TaxOffice> {
        return this.httpService.put(`api/v1/tax-offices/${taxOfficeId}`, taxOfficeUpdateRequest);
    }

    public updateTaxOfficeLogo(taxOfficeId: number, data: FormData): Promise<TaxOffice> {
        return this.httpService.upload(`api/v1/tax-offices/${taxOfficeId}/upload-logo`, data);
    }

    public getBackendTypes(): Promise<BackendType[]> {
        return this.httpService.get('api/v1/tax-offices/backend-types');
    }

    public getTaxTypes(): Promise<BackendType[]> {
        return this.httpService.get('api/v1/tax-offices/tax-types');
    }

    public getObjectionReasons(taxOfficeId: number): Promise<ReasonObjection[]> {
        return this.httpService.get(`api/v1/tax-offices/${taxOfficeId}/objection-reasons`);
    }
}
