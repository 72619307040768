import { resolve } from '@bakerware/vue-utils';
import { defineStore } from 'pinia';
import { UserSettingService } from '../../../services';
import { defaultVisualSettings } from './constants';
import { VisualSettings, VisualSettingsState } from './types';

export const useVisualSettingsStore = defineStore('visualSettings', {
    state: (): VisualSettingsState => ({
        visualSettings: defaultVisualSettings,
    }),

    getters: {
        darkMode(state): boolean {
            const { visualSettings, } = state;

            return visualSettings ? visualSettings.darkMode : false;
        },

        stripedTables(state): boolean {
            const { visualSettings, } = state;

            return visualSettings ? visualSettings.stripedTables : false;
        },
    },

    actions: {
        init(visualSettings: VisualSettings): void {
            this.visualSettings = visualSettings;
        },

        async setDarkMode(userId: number | undefined, state: boolean): Promise<boolean> {
            if (this.visualSettings) {
                const userSettingService = resolve(UserSettingService);

                await userSettingService.setDarkMode(userId, state).then(() => {
                    this.visualSettings.darkMode = state;
                });
            }

            return this.visualSettings.darkMode;
        },

        async setStripedTables(userId: number | undefined, state: boolean): Promise<boolean> {
            if (this.visualSettings) {
                const userSettingService = resolve(UserSettingService);

                await userSettingService.setStripedTables(userId, state).then(() => {
                    this.visualSettings.stripedTables = state;
                });
            }

            return this.visualSettings.stripedTables;
        },
    },
});
