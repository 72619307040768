import Component from 'vue-class-component';
import { Inject, Prop, Ref } from 'vue-property-decorator';
import html from './organisation-address-create.dialog.html';
import {IsDialogMixin} from "@bakerware/vue-dialogs";
import { AddressService, OrganisationAddressService } from "@ncnp-mono/utils";
import { Observable } from "@bakerware/vue-utils";
import type { VForm } from "@bakerware/vue-utils";
import type {Address, AddressRequest, AddressType} from "@ncnp-mono/generics";
import { AddressRules } from "../config/address-rules";

@Component({
    template: html,
})
export class OrganisationAddressCreateDialog extends IsDialogMixin {

    @Inject()
    addressService!: AddressService;

    @Inject()
    organisationAddressService!: OrganisationAddressService;

    @Ref('form')
    form!: VForm;

    @Prop()
    organisationId!: string;

    @Prop()
    addresses!: Address[];

    public result = new Observable<Address>();
    public request: AddressRequest = {}
    private types: AddressType[] = [];
    public rules = AddressRules;

    mounted(): void {
        this.getAddressTypes();
    }

    async submit(): Promise<void> {
        if (!this.form.validate()) {
            return;
        }

        this.request.active = true;

        try {
            const address = await this.organisationAddressService.createOrganisationAddress(
                this.organisationId,
                this.request
            );

            this.result.emit(address);

            this.$snackbar.success('Aanmaken adres gelukt');
            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.danger('Aanmaken adres mislukt');
        }
    }

    get unusedAddressTypes(): AddressType[] {
        return this.types.filter((type) => !this.addresses.some(
            (address) => address.addressType === type.code
        ));
    }

    async getAddressTypes(): Promise<void> {
        try {
            this.types = await this.organisationAddressService.getOrganisationAddressTypes();
        } catch (e) {
            this.$snackbar.danger('Ophalen van adres types is niet gelukt');
        }
    }
}
