import Component from 'vue-class-component';
import { Inject, Prop, Ref } from 'vue-property-decorator';
import html from './user-edit.dialog.html';
import { IsDialogMixin } from '@bakerware/vue-dialogs';
import { UserService } from '@ncnp-mono/utils';
import type { VForm } from '@bakerware/vue-utils';
import { Observable } from '@bakerware/vue-utils';
import type { AdminUserRequest, User } from '@ncnp-mono/generics';
import { UserRules } from '../../config';

@Component({
    template: html,
})
export class UserEditDialog extends IsDialogMixin {

    @Inject()
    userService!: UserService;

    @Ref()
    form!: VForm;

    @Prop()
    public user!: User;

    public isFormValid = false;

    public result = new Observable<User>();
    public request: Partial<AdminUserRequest> = {};
    public rules = UserRules;

    get tfaCheckboxDisabled() {
        return !this.user.userSettings.security.useTwoFactorAuthentication && !this.user.hasAuthenticatorSecret;
    }

    public created(): void {
        this.request = {
            userGroupId: 0,
            useTwoFactorAuthentication: this.user.userSettings.security.useTwoFactorAuthentication,
            ...this.user,
        };
    }

    public async submit() {
        if (!this.form.validate()) {
            return;
        }

        try {
            const user = await this.userService.updateUser(
                this.user.id,
                <AdminUserRequest>this.request
            );

            if (this.isCurrentUser && this.user.email !== user.email) {
                await this.$router.push({
                    name: 'login',
                });

                this.$snackbar.info('Activeer uw nieuwe e-mailadres voordat u opnieuw inlogt');
            } else {
                this.$snackbar.success('Wijzigen gebruiker gelukt');
                this.result.emit(user);
            }
        } catch (e) {
            this.$snackbar.danger('Wijzigen gebruiker mislukt');
        } finally {
            this.activeDialog.close();
        }
    }

    public get isCurrentUser(): boolean {
        return this.user.id === this.$auth.user?.id;
    }
}
