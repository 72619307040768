import Vue from 'vue';
import Component from 'vue-class-component';
import html from './users-overview.component.html';
import { User } from '@ncnp-mono/generics';
import { Inject, Prop } from 'vue-property-decorator';
import { initials, UserService } from '@ncnp-mono/utils';
import { ConfirmDialog, UserCreateDialog, UserEditDialog } from '../../dialogs';

@Component({
    template: html,
    methods: {
        initials,
    },
})
export class UsersOverviewComponent extends Vue {

    @Inject()
    userService!: UserService;

    @Prop()
    users!: User[];

    public query = '';

    private openUserCreateDialog(): void {
        this.$vueDialogs.open(UserCreateDialog, {
            dialogSettings: {
                maxWidth: '600px',
            },
        }).result.observe(() => this.$emit('update'));
    }

    public openUserEditDialog(user: User): void {
        this.$vueDialogs.open(UserEditDialog, {
            propsData: {
                user: user,
            },
            dialogSettings: {
                maxWidth: '600px',
            },
        }).result.observe(() => this.$emit('update'));
    }

    public sendActivationMail(user: User): void {
        this.$vueDialogs.open(ConfirmDialog, {
            propsData: {
                type: 'info',
                message: 'U staat op het punt om deze gebruiker een activatie e-mail te sturen',
                confirmButtonText: 'Versturen',
            },
            dialogSettings: {
                maxWidth: '728px',
            },
        }).onConfirm.observe(() => this.sendActivationMailDialogAction(user.id));
    }

    public resetPassword(user: User): void {
        this.$vueDialogs.open(ConfirmDialog, {
            propsData: {
                type: 'info',
                message: 'U staat op het punt om deze gebruiker een wachtwoord reset e-mail te sturen',
                confirmButtonText: 'Versturen',
            },
            dialogSettings: {
                maxWidth: '728px',
            },
        }).onConfirm.observe(() => this.resetPasswordDialogAction(user.id));
    }

    public async deleteUser(user: User): Promise<void> {
        this.$vueDialogs.open(ConfirmDialog, {
            propsData: {
                message: 'U staat op het punt om deze gebruiker te verwijderen',
            },
            dialogSettings: {
                maxWidth: '728px',
            },
        }).onConfirm.observe(() => this.deleteUserDialogAction(user.id));
    }

    private async sendActivationMailDialogAction(userId: number): Promise<void> {
        try {
            await this.userService.sendActivationMail(userId);
            this.$snackbar.success('Activatie email is verzonden');
        } catch (e) {
            this.$snackbar.danger('Activatie e-mail versturen mislukt, probeer opnieuw');
        }
    }

    private async deleteUserDialogAction(userId: number): Promise<void> {
        try {
            await this.userService.deleteUser(userId);

            this.$emit('update');

            this.$snackbar.success('Verwijderen gebruiker gelukt');
        } catch (e) {
            this.$snackbar.danger('Verwijderen gebruiker mislukt, probeer opnieuw');
        }
    }

    private async resetPasswordDialogAction(userId: number): Promise<void> {
        try {
            await this.userService.resetPassword(userId);

            this.$snackbar.success('Wachtwoord reset is verzonden');
        } catch (e) {
            this.$snackbar.danger('Wachtwoord reset versturen mislukt, probeer opnieuw');
        }
    }

    public getBackgroundColorClass(user: User): string {
        return user.blocked ? 'wm-user-blocked' : '';
    }

    public getColorClass(user: User): string {
        return user.active ? '' : 'wm-user-inactive';
    }

    public getColor(active: boolean): string {
        return active ? 'green' : 'red';
    }

    public isCurrentUser(user: User): boolean {
        return user.id === this.$auth.user?.id;
    }

    public get computedUsers(): User[] {
        if (this.query.length) {
            return this.users.filter((user: User) => user.name.toLowerCase().includes(this.query.toLowerCase()));
        }

        return this.users;
    }
} 
