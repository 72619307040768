import { NavigationGuardNext, Route } from 'vue-router';
import Vue from 'vue';

export function setLoginMessage (to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
    if(to.name === 'login' && to.query.message === undefined) {
        next({
            path: to.fullPath,
            query: {
                ...to.query,
                message: 'Let op: Per 15 februari 2024 is inloggen met tweefactorauthenticatie (2FA) verplicht. Wanneer 2FA nog niet is ingesteld wordt u verplicht om dit alsnog te doen.',
                type: 'warning',
            },
        });
    } else {
        next();
    }
}
