import Vue from 'vue';
import Component from 'vue-class-component';
import html from './sidebar.component.html';

@Component({
    template: html,
})
export class SidebarComponent extends Vue {
}

