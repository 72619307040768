import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import { DossierFileService } from '@ncnp-mono/utils';

@Component
export class InteractsWithDossierFilesMixin extends Vue {

    @Inject()
    public dossierFileService!: DossierFileService;

    public async downloadDossierFile(event: { id: number, name: string, mimeType: string }): Promise<void> {
        try {
            await this.dossierFileService.downloadDossierFile(
                event.id,
                event.name,
                event.mimeType
            );
        } catch (e) {
            this.$snackbar.danger('Er is een fout opgetreden bij het downloaden van het bestand');
        }
    }
}
