import { IsDialogMixin } from '@bakerware/vue-dialogs';
import html from './personal-information.dialog.html';
import Component from 'vue-class-component';
import { UserRequest } from '@ncnp-mono/generics';
import { email, hasNoScriptTags, max, required } from '@bakerware/vue-validation-rules';
import { Inject } from 'vue-property-decorator';
import { UserService } from '@ncnp-mono/utils';
import { EntityNotUniqueException } from '@bakerware/http';

@Component({
    template: html,
})
export class PersonalInformationDialog extends IsDialogMixin {

    @Inject()
    userService!: UserService;

    public request: UserRequest = {
        name: this.$auth.user?.name,
        email: this.$auth.user?.email,
        active: this.$auth.user?.active,
        blocked: false,
    };

    public rules = {
        name: [ required, max, hasNoScriptTags, ],
        email: [ required, email, ],
    };

    public formValid = false;

    public save() {
        if (this.formValid) {
            this.userService
                .updateUser(this.$auth.user?.id, this.request)
                .then((user) => {
                    if (this.request.email !== this.$auth.user?.email) {
                        this.$snackbar.info('Activeer uw nieuwe e-mail adres voordat u opnieuw inlogt');

                        this.$router.push({
                            name: 'login',
                        });
                    } else {
                        this.$snackbar.success('Wijzigingen zijn opgeslagen');

                        // TODO Auth service needs to have a setter for user
                        // this.$auth.user = user;
                    }

                    this.activeDialog.close();
                })
                .catch((exception) => {
                    if (exception instanceof EntityNotUniqueException) {
                        this.$snackbar.danger('E-mail adres is niet uniek');
                    } else {
                        this.$snackbar.danger('Wijzigingen zijn niet opgeslagen');
                    }
                });
        }
    }
}
