import { RouteConfig } from 'vue-router';
import { BaseComponent } from "../../components/base/base.component";
import { AuthRoutingModule } from "@bakerware/vue-authentication-module";
import {
    DashboardRoutingModule,
    DossierRoutingModule,
    OrganisationRoutingModule,
    TaxOfficeRoutingModule,
    TaxOfficeUserRoutingModule,
    UserProfileRoutingModule
} from "../../../modules";

export const routes: RouteConfig[] = [
    ...AuthRoutingModule,
    {
        path: '/',
        name: 'core',
        component: BaseComponent,
        meta: {
            requiresAuth: true,
            breadcrumb: {
                title: 'Dashboard',
                to: 'dashboard'
            },
        },
        children: [
            ...DashboardRoutingModule,
            ...TaxOfficeUserRoutingModule,
            ...TaxOfficeRoutingModule,
            ...OrganisationRoutingModule,
            ...DossierRoutingModule,
            ...UserProfileRoutingModule,
        ],
    },
];
