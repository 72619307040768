import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { IsDialogMixin, Observable } from '@bakerware/vue-dialogs';
import html from './confirm.dialog.html';

@Component({
    template: html,
})
export class ConfirmDialog extends IsDialogMixin {
    @Prop({
        default: 'warning',
    })
    type!: 'error' | 'warning' | 'info' | 'success';

    @Prop({
        default: 'Weet u het zeker?',
    })
    title!: string;

    @Prop({
        default: '',
    })
    message!: string;

    @Prop({
        default: 'Annuleren',
    })
    closeButtonText!: string;

    @Prop({
        default: 'Verwijderen',
    })
    confirmButtonText!: string;

    public onConfirm: Observable<boolean> = new Observable<boolean>();

    public confirm(): void {
        this.onConfirm.emit(true);
        this.activeDialog.close();
    }
}
