import Component from "vue-class-component";
import { Inject, Prop, Ref } from "vue-property-decorator";
import html from "./organisation-tax-office-edit.dialog.html";
import type { VForm } from "@bakerware/vue-utils";
import { IsDialogMixin, Observable } from "@bakerware/vue-dialogs";
import { OrganisationService } from "@ncnp-mono/utils";
import { AssociationRequest } from "@ncnp-mono/generics";
import {max, numeric, required} from "@bakerware/vue-validation-rules";

@Component({
    template: html
})
export class OrganisationTaxOfficeEditDialog extends IsDialogMixin {

    @Inject()
    public organisationService!: OrganisationService;

    @Ref("form")
    public form!: VForm;

    @Prop()
    subjectNumber!: number;

    public result = new Observable<AssociationRequest>();
    public request: AssociationRequest = {
        isAssociated: true,
    };

    public rules = {
        subjectNumber: [required, numeric, (v: number) => max(v, 10)]
    };

    mounted(): void {
        this.request.subjectNumber = this.subjectNumber;
    }

    async submit(): Promise<void> {
        if (this.form.validate()) {
            this.result.emit(this.request);
            this.activeDialog.close();
        }
    }
}
