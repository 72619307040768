import { HttpService } from '@bakerware/http';
import { ActionType, ActionTypeTemplate, ActionTypeTemplateRequest } from '@ncnp-mono/generics';
import { Register } from '@bakerware/vue-utils';

@Register()
export class ActionTypeService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public getAllActionTypes(): Promise<ActionType[]> {
        return this.httpService.get('api/v1/actiontypes');
    }

    public getActionTypeTemplateByTaxOffice(actionTypeId: number, taxOfficeId: number): Promise<ActionTypeTemplate> {
        return this.httpService.get(`api/v1/tax-offices/${taxOfficeId}/actiontypes/${actionTypeId}/template`);
    }

    public createActionTypeTemplate(actionTypeId: number, request: ActionTypeTemplateRequest): Promise<ActionTypeTemplate> {
        return this.httpService.post(`api/v1/actiontypes/${actionTypeId}/template`, request);
    }

    public updateActionTypeTemplate(actionTypeTemplateId: number, request: ActionTypeTemplateRequest): Promise<ActionTypeTemplate> {
        return this.httpService.put(`api/v1/action-type-templates/${actionTypeTemplateId}`, request);
    }
}
