import Component from "vue-class-component";
import html from "./step-two.component.html";
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import type {DossierRequest} from "@ncnp-mono/generics";
import {hasNoScriptTags, max, numeric, postcode, required} from "@bakerware/vue-validation-rules";

@Component({
    template: html,
})
export class StepTwoComponent extends Vue {

    @Prop()
    dossierRequest!: DossierRequest;

    @Prop()
    isFormValid!: boolean;

    public rules = {
        name: [required, hasNoScriptTags],
        street: [required, hasNoScriptTags],
        number: [required, numeric],
        numberLetter: [hasNoScriptTags],
        numberSuffix: [hasNoScriptTags],
        zipcode: [required, postcode],
        city: [required, hasNoScriptTags],
        subjectNumber: [numeric, (v: number) => max(v, 10), this.ruleIsLinked],
        claimNumber: [numeric, (v: number) => max(v, 15), this.ruleIsLinked],
    };

    private ruleIsLinked(): boolean | string {
        //@TODO: validatie bouwen voor 'Subjectnummer en aanslagnummer bestaan en aan elkaar gelinkt'
        return true;
    }
}
