import Vue from 'vue';
import Component from 'vue-class-component';
import html from './general-user-information.component.html';
import { PersonalInformationDialog } from '../../dialogs';

@Component({
    template: html,
})
export class GeneralUserInformationComponent extends Vue {
    public openPersonalInformationDialog() {
        this.$vueDialogs.open(PersonalInformationDialog);
    }
} 
