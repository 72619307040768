import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { ChangesOrder, Collection, Dossier, FiltersDossiers, PaginatesResults } from '@ncnp-mono/generics';

@Register()
export class TaxOfficeDossierService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public getTaxOfficeDossiers(paginatorRequest: PaginatesResults & ChangesOrder & FiltersDossiers): Promise<Collection<Dossier>> {
        return this.httpService.post('api/v1/tax-office-dossiers', paginatorRequest);
    }
}
