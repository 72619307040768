import Vue from "vue";
import Component from 'vue-class-component';
import { Inject, Watch } from 'vue-property-decorator';
import html from './organisations-overview.component.html';
import {OrganisationService, TaxOfficeOrganisationService, TaxOfficeService} from "@ncnp-mono/utils";
import {
    Address, AssociationRequest,
    ChangesOrder,
    Organisation,
    PaginatesResults,
    TaxOffice
} from "@ncnp-mono/generics";
import { DataOptions, DataTableHeader } from "vuetify";
import { OrganisationsOverviewHeaders } from "../../config/organisations-overview-headers";
import { PaginationFooterSettings } from "../../config/pagination-footer-settings";
import { OrganisationCreateDialog } from "../../dialogs/organisation-create.dialog";
import { OrganisationTaxOfficeEditDialog } from "../../dialogs/organisation-tax-office-edit.dialog";

@Component({
    template: html,
})

export class OrganisationsOverviewComponent extends Vue {
    @Inject()
    organisationService!: OrganisationService;

    @Inject()
    taxOfficeOrganisationService!: TaxOfficeOrganisationService;

    @Inject()
    taxOfficeService!: TaxOfficeService;

    @Watch('options', { deep: true, })
    async onOptionsChange(): Promise<void> {
        await this.getOrganisations();
    }

    @Watch('searchTerm')
    async onFilterChange(): Promise<void> {
        clearTimeout(this.timerId);

        this.timerId = window.setTimeout(this.getOrganisations, 300);
    }

    public loading = true;
    public loadingText = 'Organisaties worden opgehaald. Een ogenblik geduld.';
    public timerId?: number;
    public searchTerm = '';

    public headers: DataTableHeader[] = OrganisationsOverviewHeaders;
    public footerSettings = PaginationFooterSettings;
    public options: DataOptions | null = null;
    public totalOrganisations = 0;
    public taxOffice?: TaxOffice | null = null;
    public organisations: Organisation[] = [];

    beforeMount(): void {
        this.getTaxOffice();
    }

    public openCreateDialog(): void {
        const createDialog = this.$vueDialogs.open(OrganisationCreateDialog, {
            dialogSettings: {
                maxWidth: '600px',
            },
        });
        createDialog.result.observe((organisation) => {
            this.organisations.push(organisation);
        });
    }

    private async getTaxOffice(): Promise<void> {
        this.taxOffice = await this.taxOfficeService.getTaxOfficeForUser();
    }

    async createOrUpdateTaxOfficeAssociation(organisation: Organisation): Promise<void> {
        this.$vueDialogs.open(OrganisationTaxOfficeEditDialog, {
            dialogSettings: {
                maxWidth: '600px',
            },
            propsData: {
                subjectNumber: organisation.subjectNumber
            }
        }).result.observe(result => {
            this.updateAssociation(organisation.id, result);
        });
    }

    private async updateAssociation(organisationId: number, associationRequest: AssociationRequest): Promise<void> {
        try {
            await this.taxOfficeOrganisationService.updateTaxOfficeOrganisationAssociation(organisationId, associationRequest);
            await this.getOrganisations();
        } catch (e) {
            this.$snackbar.danger('Toegang geven mislukt');
        }
    }

    async getOrganisations() {
        this.loading = true;

        try {
            const paginatorRequest: { searchTerm: string } & PaginatesResults & ChangesOrder = {
                searchTerm: this.searchTerm,
                page: this.options?.page ?? 1,
                limit: this.options?.itemsPerPage ?? 10,
                orderBy: '',
                orderDirection: 'DESC',
            };

            const response = await this.organisationService.getAllOrganisations(paginatorRequest);

            const organisations = response.data;
            this.organisations = this.addVisitAddress(organisations);

            this.totalOrganisations = +response.meta.total;
        } catch (e) {
            this.$snackbar.danger('Het laden van de organisaties is niet gelukt');
        }

        this.loading = false;
    }

    viewClickedRow(organisation: Organisation) {
        this.$router.push({
            name: 'organisation-general-data',
            params: { organisationId: organisation.id.toString(), },
            query: { q: this.searchTerm, },
        });
    }

    addVisitAddress(organisations: Organisation[]): Organisation[] {
        for (let i = organisations.length - 1; i >= 0; i--) {
            const address = organisations[i].addresses.find(
                (address: Address) => address.addressType === 'VISIT'
            );

            if (address) {
                organisations[i].visitAddress = `${address.street} ${address.number}, ${address.zipCode} ${address.city}`;
            }
        }

        return organisations;
    }
}
