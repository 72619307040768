import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { Address, AddressRequest, AddressType } from '@ncnp-mono/generics';

@Register()
export class OrganisationAddressService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public createOrganisationAddress(organisationId: string, addressRequest: AddressRequest): Promise<Address> {
        return this.httpService.post(`api/v1/organisations/${organisationId}/addresses`, addressRequest);
    }

    public getOrganisationAddressTypes(): Promise<AddressType[]> {
        return this.httpService.get('api/v1/organisations/addresses/types');
    }
}
