import Vue from 'vue';
import Component from 'vue-class-component';
import html from './subject-data-component.html';
import { Prop } from 'vue-property-decorator';
import type { Dossier } from '@ncnp-mono/generics';
import { formatAddress } from '@ncnp-mono/utils';

@Component({
    template: html,
    methods: {
        formatAddress,
    },
})

export class SubjectDataComponent extends Vue {
    @Prop()
    public dossier!: Dossier;
}
