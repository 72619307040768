import Component from 'vue-class-component';
import {Inject, Prop, Ref} from 'vue-property-decorator';
import html from './organisation-user-edit.dialog.html';
import {IsDialogMixin} from "@bakerware/vue-dialogs";
import type {VForm} from "@bakerware/vue-utils";
import {Observable} from "@bakerware/vue-utils";
import type {Organisation, OrganisationUser, OrganisationUserRequest} from "@ncnp-mono/generics";
import {OrganisationUserService, UserService} from "@ncnp-mono/utils";
import {OrganisationUserRules} from "../config/organisation-user-rules";

@Component({
    template: html
})
export class OrganisationUserEditDialog extends IsDialogMixin {

    @Inject()
    organisationUserService!: OrganisationUserService;

    @Inject()
    userService!: UserService;

    @Ref('form')
    form!: VForm;

    @Prop()
    organisation!: Organisation;

    @Prop()
    organisationUser!: OrganisationUser;

    public result = new Observable<OrganisationUser>();
    public request: OrganisationUserRequest = {};
    public rules = OrganisationUserRules;

    public created(): void {
        this.request = {
            ...this.organisationUser,
        };
    }

    async submit(): Promise<void> {
        if (!this.form.validate()) {
            return;
        }

        try {
            const organisationUser =
                await this.organisationUserService.updateOrganisationUser(
                    this.organisation.id,
                    this.organisationUser.id,
                    this.request
                );

            this.$snackbar.success('Wijzigen gebruiker gelukt');

            this.result.emit(organisationUser);
            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.danger('Wijzigen gebruiker mislukt');
        }
    }
}
