import Vue from "vue";
import Component from "vue-class-component";
import html from "./dossier-router-view.component.html";

@Component({
    template: html
})
export class DossierRouterViewComponent extends Vue {

}
