import Vue from 'vue';
import Component from 'vue-class-component';
import html from './organisation-router-view.component.html';

@Component({
    template: html
})

export class OrganisationRouterViewComponent extends Vue {

}
