import Component from "vue-class-component";
import { Inject, Prop, Ref } from "vue-property-decorator";
import html from "./tax-office-edit.dialog.html";
import { IsDialogMixin } from "@bakerware/vue-dialogs";
import { TaxOfficeService } from "@ncnp-mono/utils";
import type { BackendType, TaxOffice, TaxOfficeUpdateRequest, TaxType } from "@ncnp-mono/generics";
import {Observable} from "@bakerware/vue-utils";
import type {VForm} from "@bakerware/vue-utils";
import { TaxOfficeRules } from "../../../config/tax-office.rules";

@Component({
    template: html
})
export class TaxOfficeEditDialog extends IsDialogMixin {

    @Inject()
    taxOfficeService!: TaxOfficeService;

    @Ref()
    form!: VForm;

    @Prop()
    taxOffice!: TaxOffice;

    public result = new Observable<TaxOffice>();
    public request: TaxOfficeUpdateRequest = {};
    public backendTypes: BackendType[] = [];
    public taxTypes: TaxType[] = [];
    public rules = TaxOfficeRules;

    public created(): void {
        this.request = {
            id: this.taxOffice.id,
            name: this.taxOffice.name,
            shortName: this.taxOffice.shortName,
            code: this.taxOffice.code,
            kvk: this.taxOffice.kvk,
            iban: this.taxOffice.iban,
            email: this.taxOffice.email,
            phoneNumber: this.taxOffice.phoneNumber,
            active: this.taxOffice.active,
            backendType: this.taxOffice.backendType,
            taxType: this.taxOffice.taxType,
            offline: this.taxOffice.offline,
            offlineMessage: this.taxOffice.offlineMessage
        };
    }

    private beforeMount(): void {
        this.taxOfficeService.getBackendTypes().then(backendTypes => this.backendTypes = backendTypes);
        this.taxOfficeService.getTaxTypes().then(taxTypes => this.taxTypes = taxTypes);
    }

    public async submit(): Promise<void> {
        if (!this.form.validate()) {
            return;
        }

        try {
            const taxOffice = await this.taxOfficeService.updateTaxOffice(this.taxOffice.id, this.request);

            this.result.emit(taxOffice);
            this.$snackbar.success("De gegevens zijn succesvol gewijzigd");
            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.danger("Het wijzigen van de gegevens is mislukt");
        }
    }
}
