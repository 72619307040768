import Vue from "vue";
import Component from "vue-class-component";
import html from "./dashboard-overview.component.html";

@Component({
    template: html,
})
export class DashboardOverviewComponent extends Vue {

}
