import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { ContactRequest, Contact } from '@ncnp-mono/generics';


@Register()
export class TaxOfficeContactService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public createTaxOfficeContact(taxOfficeId: string, contactRequest: ContactRequest): Promise<Contact> {
        return this.httpService.post(`api/v1/tax-offices/${taxOfficeId}/contact`, contactRequest);
    }
}
