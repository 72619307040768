import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';

@Register()
export class UserSettingService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public setDarkMode(userId: number | undefined, status: boolean): Promise<void> {
        return this.httpService.put(`api/v1/users/${userId}/settings/visual/darkmode`, {
            status: status,
        });
    }

    public setStripedTables(userId: number | undefined, status: boolean): Promise<void> {
        return this.httpService.put(`api/v1/users/${userId}/settings/visual/stripedtables`, {
            status: status,
        });
    }
}
