import nl from 'vuetify/src/locale/nl';
import colors from 'vuetify/lib/util/colors'

export const vuetifySettings = {
    lang: {
        locales: { nl, },
        current: 'nl',
    },
    theme: {
        dark: false,
        themes: {
            light: {
                primary: colors.blue.lighten1,
                secondary: colors.blue.lighten4,
                accent: colors.blue.darken2,
            },
            dark: {
                primary: colors.blue.lighten2,
                secondary: colors.grey.darken4,
                accent: colors.blue.lighten1,
            },
        },
    },
};
