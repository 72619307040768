import Vue from 'vue';
import Component from 'vue-class-component';
import html from './user-security-settings.component.html';
import { ChangePasswordDialog } from '../../dialogs';
import { ConfirmedIdentity,
    ConfirmIdentityComponent,
    JwtBundleSettings,
    SetupTfaComponent } from '@bakerware/vue-authentication-module';
import { Emit, Prop } from 'vue-property-decorator';

@Component({
    template: html,
})
export class UserSecuritySettingsComponent extends Vue {
    @Prop()
    jwtBundleSettings!: JwtBundleSettings;
    
    public openPasswordDialog() {
        this.$vueDialogs.open(ChangePasswordDialog);
    }

    get twoFactorAuthenticationCanBeSet(): boolean {
        return this.jwtBundleSettings?.useTwoFactorAuthentication ?? false;
    }

    get showEnableTfaButton(): boolean {
        return !(this.$auth?.user?.userSettings?.security?.useTwoFactorAuthentication ?? true);
    }

    get showResetTfaButton(): boolean {
        return (this.$auth?.user?.userSettings?.security?.useTwoFactorAuthentication ?? false);
    }

    enableTfa() {
        this.$vueDialogs.open(SetupTfaComponent, {
            dialogSettings: {
                maxWidth: '800px',
                persistent: true,
                scrollable: this.$vuetify.breakpoint.xs,
            },
        });
    }

    @Emit()
    async resetTfa() {
        const dialog = this.$vueDialogs.open(ConfirmIdentityComponent, {
            dialogSettings: {
                maxWidth: '500px',
                persistent: true,
                scrollable: this.$vuetify.breakpoint.xs,
            },
        });

        return await new Promise((resolve) => {
            dialog.confirmedIdentity.observe((identity: ConfirmedIdentity) => {
                resolve(identity);
            });
        });
    }
}
