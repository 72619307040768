import Component from 'vue-class-component';
import { Inject, Prop, Ref } from 'vue-property-decorator';
import html from './organisation-edit.dialog.html';
import {IsDialogMixin} from "@bakerware/vue-dialogs";
import {OrganisationService} from "@ncnp-mono/utils";
import { Observable } from "@bakerware/vue-utils";
import type { VForm } from "@bakerware/vue-utils";
import type {Organisation, OrganisationRequest} from "@ncnp-mono/generics";
import {OrganisationRules} from "../config/organisation.rules";

@Component({
    template: html
})
export class OrganisationEditDialog extends IsDialogMixin {

    @Inject()
    organisationService!: OrganisationService;

    @Ref('form')
    form!: VForm;

    @Prop()
    organisation!: Organisation;

    public result = new Observable<Organisation>();
    public request: OrganisationRequest = {};
    private rules = OrganisationRules;
    public kvkBranchNumberMessage = '';

    public created(): void {
        this.request = {
            ...this.organisation,
        };
    }

    async submit(): Promise<void> {
        if (!this.form.validate()) {
            return;
        }

        try {
            const organisation =
                await this.organisationService.updateOrganisation(
                    this.organisation.id,
                    this.request
                );

            this.result.emit(organisation);
            this.$snackbar.success('Wijzigen organisatie gelukt');
            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.danger('Wijzigen organisatie mislukt');
        }
    }

    async checkKvkBranchNumberExists(
        organisationRequest: OrganisationRequest
    ): Promise<void> {
        if (
            this.rules.kvk.reduce(
                (invalid, rule) => invalid || rule(this.request.kvk) !== true,
                false
            )
        ) {
            return;
        }

        if (
            this.rules.branchNumber.reduce(
                (invalid, rule) =>
                    invalid || rule(this.request.branchNumber) !== true,
                false
            )
        ) {
            return;
        }

        this.clearErrors();

        const organisationExists =
            await this.organisationService.checkKvkBranchNumberExists(
                organisationRequest,
                this.organisation.id
            );

        if (organisationExists) {
            this.kvkBranchNumberMessage = 'KVK en vestigingsnummer bestaan al';
        }
    }

    clearErrors(): void {
        this.kvkBranchNumberMessage = '';
    }
}
