import Component from 'vue-class-component';
import Vue from "vue";
import html from './organisation-details.component.html';
import {Inject, Prop} from "vue-property-decorator";
import {
    BreadcrumbService,
    initials,
    OrganisationService,
    OrganisationUserService,
    TaxOfficeOrganisationService,
    UserService
} from "@ncnp-mono/utils";
import {Address, AssociationRequest, Organisation, OrganisationUser} from "@ncnp-mono/generics";
import {OrganisationEditDialog} from "../../dialogs/organisation-edit.dialog";
import {OrganisationAddressEditDialog} from "../../dialogs/organisation-address-edit.dialog";
import {OrganisationAddressCreateDialog} from "../../dialogs/organisation-address-create.dialog";
import {OrganisationUserEditDialog} from "../../dialogs/organisation-user-edit.dialog";
import {OrganisationUserCreateDialog} from "../../dialogs/organisation-user-create.dialog";
import {DataTableHeader} from "vuetify";
import { OrganisationUsersTableHeaders } from "../../config/organisation-users-table-headers";
import {ConfirmDialog} from "@ncnp-mono/components";
import {OrganisationTaxOfficeEditDialog} from "../../dialogs/organisation-tax-office-edit.dialog";

@Component({
    template: html,
    methods: {
        initials,
    }
})
export class OrganisationDetailsComponent extends Vue {

    @Inject()
    private breadcrumbService!: BreadcrumbService;

    @Inject()
    organisationService!: OrganisationService;

    @Inject()
    organisationUserService!: OrganisationUserService;

    @Inject()
    taxOfficeOrganisationService!: TaxOfficeOrganisationService;

    @Inject()
    userService!: UserService;

    @Prop()
    private organisationId!: number;

    public organisation: Organisation | null = null;
    public organisationUsers: OrganisationUser[] = [];

    public headers: DataTableHeader[] = OrganisationUsersTableHeaders;
    private loading = true;
    private loadingText = 'Gebruikers worden opgehaald. Een ogenblik geduld.';
    private search = '';

    beforeMount(): void {
        this.getOrganisation().then(() => {
            if (this.organisation) {
                this.updateBreadcrumbs(this.organisation.name);
            }

            this.getOrganisationUsers();
        });
    }

    updateBreadcrumbs(organisationName: string): void {
        this.breadcrumbService.updateBreadcrumbResolver(organisationName);
    }

    async getOrganisation(): Promise<void> {
        await this.organisationService.getOrganisation(this.organisationId).then((organisation) => {
            this.organisation = organisation;
        });
    }

    async getOrganisationUsers(): Promise<void> {
        this.organisationUsers = await this.organisationUserService.getAllOrganisationUsers(this.organisationId);

        this.loading = false;
    }

    deleteOrganisationUser(organisationUser: OrganisationUser): void {
        const index = this.organisationUsers.findIndex((_organisationUser: OrganisationUser) =>
            _organisationUser.id === organisationUser.id
        );

        this.organisationUsers.splice(index, 1);
    }

    deleteUser(organisationUser: OrganisationUser): void {
        this.$vueDialogs.open(ConfirmDialog, {
            propsData: {
                message: 'U staat op het punt om deze gebruiker te verwijderen',
            },
            dialogSettings: {
                maxWidth: '728px',
            },
        }).onConfirm.observe(() => this.deleteUserDialogAction(organisationUser.id));
    }

    private async deleteUserDialogAction(organisationUserId: number): Promise<void> {
        try {
            await this.userService.deleteUser(organisationUserId);

            this.organisationUsers = this.organisationUsers.filter(
                (user) => user.id !== organisationUserId
            );

            this.$snackbar.success('Verwijderen gebruiker gelukt');
        } catch (e) {
            this.$snackbar.danger('Verwijderen gebruiker mislukt, probeer opnieuw');
        }
    }

    public openEditDialog(): void {
        const editDialog = this.$vueDialogs.open(OrganisationEditDialog, {
            propsData: {
                organisation: this.organisation,
            },
            dialogSettings: {
                maxWidth: '800px',
            },
        });

        editDialog.result.observe((organisation: Organisation) => {
            this.organisation = organisation;
        });
    }

    public openAddressEditDialog(address: Address): void {
        const addressEditDialog = this.$vueDialogs.open(
            OrganisationAddressEditDialog,
            {
                propsData: {
                    address,
                    addresses: this.organisation?.addresses,
                },
                dialogSettings: {
                    maxWidth: '800px',
                },
            }
        );

        addressEditDialog.result.observe((_address?: Address | null) =>
            _address
                ? this.updateOrganisationAddress(_address)
                : this.deleteOrganisationAddress(address)
        );
    }

    private updateOrganisationAddress(address: Address): void {
        const index = this.organisation?.addresses.findIndex(_address => _address.id === address.id);
        this.organisation?.addresses.splice(<number>index, 1, address);
    }

    private deleteOrganisationAddress(address: Address): void {
        const index = this.organisation?.addresses.findIndex(_address => _address.id === address.id);
        this.organisation?.addresses.splice(<number>index, 1);
    }

    public openAddressCreateDialog(): void {
        const addressCreateDialog = this.$vueDialogs.open(
            OrganisationAddressCreateDialog,
            {
                propsData: {
                    organisationId: this.organisation?.id,
                    addresses: this.organisation?.addresses,
                },
                dialogSettings: {
                    maxWidth: '800px',
                },
            }
        );

        addressCreateDialog.result.observe((address: Address) => {
            this.organisation?.addresses.push(address);
        });
    }

    public openUserEditDialog(user: OrganisationUser): void {
        const index = this.organisationUsers.findIndex(
            (_user: OrganisationUser) => _user.id === user.id
        );

        const userEditDialog = this.$vueDialogs.open(
            OrganisationUserEditDialog,
            {
                propsData: {
                    organisationUser: user,
                    organisation: this.organisation,
                },
                dialogSettings: {
                    maxWidth: '800px',
                },
            }
        );

        userEditDialog.result.observe((result: OrganisationUser | null) => {
            result
                ? this.organisationUsers.splice(index, 1, result)
                : this.organisationUsers.splice(index, 1);
        });
    }

    public openUserCreateDialog(): void {
        const userCreateDialog = this.$vueDialogs.open(
            OrganisationUserCreateDialog,
            {
                propsData: {
                    organisation: this.organisation,
                },
                dialogSettings: {
                    maxWidth: '800px',
                },
            }
        );

        userCreateDialog.result.observe((user: OrganisationUser) => {
            this.organisationUsers.push(user);
        });
    }

    async sendActivationMail(organisationUser: OrganisationUser): Promise<void> {
        await this.userService.sendActivationMail(organisationUser.id);
    }

    async resetPassword(organisationUser: OrganisationUser): Promise<void> {
        await this.userService.resetPassword(organisationUser.id);
    }

    async toggleTaxOfficeAssociation(organisation: Organisation): Promise<void> {
        if (organisation.accessToTaxOffice) {
            this.$vueDialogs.open(OrganisationTaxOfficeEditDialog, {
                dialogSettings: {
                    maxWidth: '600px',
                },
                propsData: {
                    subjectNumber: organisation.subjectNumber
                }
            }).result.observe(result => {
                this.updateAssociation(organisation.id, result);
            });
        } else {
            const associationRequest: AssociationRequest = {
                isAssociated: false
            }

            await this.updateAssociation(organisation.id, associationRequest);
        }
    }

    private async updateAssociation(organisationId: number, associationRequest: AssociationRequest): Promise<void> {
        try {
            await this.taxOfficeOrganisationService.updateTaxOfficeOrganisationAssociation(organisationId, associationRequest);
        } catch (e) {
            this.$snackbar.danger('Toegang geven mislukt');
        }
    }

    async updateSetting(name: string, value: boolean): Promise<void> {
        try {
            await this.organisationService.updateSetting(this.organisationId, name, value);
        } catch (e) {
            this.$snackbar.danger('Wijzigen instelling mislukt');
        }
    }

    isUserBlockedClass(user: OrganisationUser): string {
        return user.blocked ? 'data-table-user-blocked' : '';
    }

    getColor(active: boolean): string {
        return active ? 'green' : 'red';
    }
}
