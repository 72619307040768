import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { DossierFile } from '@ncnp-mono/generics';
import fileDownload from 'js-file-download';

@Register()
export class DossierFileService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public getDossierFile(dossierFile: DossierFile): Promise<Blob> {
        return this.httpService.download<Blob>(
            `api/v1/dossier-files/${dossierFile.id}`,
            'GET',
            dossierFile.mimetype
        );
    }

    public downloadDossierFile(
        dossierFileId: number,
        fileName: string,
        mimeType: string
    ): Promise<Blob|void> {
        return this.httpService.download<Blob>(`api/v1/dossier-files/${dossierFileId}`,'GET', mimeType)
            .then((blob) => {
                fileDownload(blob, fileName, mimeType);
            });
    }
}
