import { hasNoScriptTags, max, postcode, required } from '@bakerware/vue-validation-rules';

export const AddressRules = {
    street: [
        required,
        max,
        hasNoScriptTags,
    ],
    number: [
        required, (v: unknown) => max(v, 11),
    ],
    zipCode: [
        required, postcode,
    ],
    city: [
        required,
        max,
        hasNoScriptTags,
    ],
    type: [
        required,
    ],
};
