import { Tag } from '@bakerware/document-creation-admin';
import { HttpService } from '@bakerware/http';
import { Register } from '@bakerware/vue-utils';

@Register()
export class TagService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public getAllTags(): Promise<Tag[]> {
        return this.httpService.get('api/v1/tags');
    }
}
