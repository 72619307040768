import Component from 'vue-class-component';
import {Inject, Prop, Ref} from 'vue-property-decorator';
import html from './organisation-user-create.dialog.html';
import {IsDialogMixin} from "@bakerware/vue-dialogs";
import type {Organisation, OrganisationUser, OrganisationUserRequest} from "@ncnp-mono/generics";
import type {VForm} from "@bakerware/vue-utils";
import {Observable} from "@bakerware/vue-utils";
import {OrganisationUserService, UserService} from "@ncnp-mono/utils";
import {OrganisationUserRules} from "../config/organisation-user-rules";

@Component({
    template: html,
})
export class OrganisationUserCreateDialog extends IsDialogMixin {

    @Inject()
    organisationUserService!: OrganisationUserService;

    @Inject()
    userService!: UserService;

    @Ref('form')
    form!: VForm;

    @Prop()
    organisation!: Organisation;

    public result = new Observable<OrganisationUser>();
    public request: OrganisationUserRequest = {};
    public rules = OrganisationUserRules;

    async submit(): Promise<void> {
        if (!this.form.validate()) {
            return;
        }

        try {
            const organisationUser =
                await this.organisationUserService.createOrganisationUser(
                    this.organisation.id,
                    this.request
                );
            this.result.emit(organisationUser);
            this.$snackbar.success('Aanmaken gebruiker gelukt');
            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.danger('Aanmaken gebruiker mislukt');
        }
    }
}
