import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { BulkBatchRow, BulkBatchRowQueryDto, BulkUpdateBulkBatchRowsRequest } from '@ncnp-mono/generics';

@Register()
export class BulkBatchRowService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public getBulkBatchRows(bulkBatchId: string, params: BulkBatchRowQueryDto = {}): Promise<BulkBatchRow[]> {
        return this.httpService.get(`api/v1/bulk-batch/${bulkBatchId}/bulk-batch-rows`, params);
    }

    public bulkUpdateBulkBatchRows(bulkBatchId: string, request: BulkUpdateBulkBatchRowsRequest): Promise<BulkBatchRow[]> {
        return this.httpService.put(`api/v1/bulk-batch/${bulkBatchId}/bulk-batch-rows`, request);
    }
}

