import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { AssociationRequest, Organisation } from '@ncnp-mono/generics';

@Register()
export class TaxOfficeOrganisationService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public getAllTaxOfficeOrganisations(): Promise<Organisation[]> {
        return this.httpService.get('api/v1/tax-office-organisations');
    }

    public updateTaxOfficeOrganisationAssociation(organisationId: number, associationRequest: AssociationRequest): Promise<boolean> {
        return this.httpService.put(`api/v1/organisations/${organisationId}/tax-office`, associationRequest);
    }
}
