import Component from 'vue-class-component';
import { Inject, Ref } from 'vue-property-decorator';
import html from './user-create.dialog.html';
import { IsDialogMixin } from '@bakerware/vue-dialogs';
import { AdminUserService, TaxOfficeUserService, UserService } from '@ncnp-mono/utils';
import type { VForm } from '@bakerware/vue-utils';
import { Observable } from '@bakerware/vue-utils';
import { AdminUserRequest, User } from '@ncnp-mono/generics';
import { UserRules } from '../../config';

@Component({
    template: html,
})
export class UserCreateDialog extends IsDialogMixin {

    @Inject()
    adminUserService!: AdminUserService;

    @Inject()
    taxOfficeUserService!: TaxOfficeUserService;

    @Inject()
    userService!: UserService;

    @Ref()
    form!: VForm;

    public result = new Observable<User>();
    public request: Partial<AdminUserRequest> = {
    };
    public rules = UserRules;

    public async submit() {
        if (!this.form.validate()) {
            return;
        }

        try {
            const user = await this.createUser();

            this.$snackbar.success('Aanmaken gebruiker gelukt');

            this.result.emit(user);

            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.danger('Aanmaken gebruiker mislukt');
        }
    }

    public async createUser(): Promise<User> {
        return await this.adminUserService.createAdminUser(this.request as AdminUserRequest);
    }
}
