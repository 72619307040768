import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { AddressType } from '@ncnp-mono/generics';

@Register()
export class AddressTypeService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public getAddressTypes(): Promise<AddressType[]> {
        return this.httpService.get('api/v1/addresses/types');
    }
}
