import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';

export interface CreateTaxOfficeAccessTokenRequest {
    taxOfficeId: number,
    name: string | null,
    expiresAt: string | null,
    scopes: string[]
}

export interface TaxOfficeAccessTokenInterface {
    id: number;
    name: string | null,
    expiresAt: string | null,
    createdAt: string,
    updatedAt: string,
}

export interface TokenScope {
    id: number;
    name: string;
    description: string;
}

export interface RawTokenResponse {
    token: string,
    accessToken: TaxOfficeAccessTokenInterface
}

@Register()
export class TaxOfficeAccessTokensService {
    public constructor(protected readonly httpService: HttpService) {
    }
    
    public getTaxOfficeAccessTokens(taxOfficeId: number): Promise<TaxOfficeAccessTokenInterface[]> {
        return this.httpService.get(`/api/v1/tax-offices/${taxOfficeId}/access-tokens`);
    }

    public createAccessToken(taxOfficeId: number, data: CreateTaxOfficeAccessTokenRequest): Promise<RawTokenResponse> {
        return this.httpService.post(`/api/v1/tax-offices/${taxOfficeId}/access-tokens`, data);
    }

    public revokeAccessToken(taxOfficeId: number, accessTokenId: number): Promise<void> {
        return this.httpService.delete(`/api/v1/tax-offices/${taxOfficeId}/access-tokens/${accessTokenId}`);
    }

    public getAllScopes(): Promise<TokenScope[]> {
        return this.httpService.get('/api/v1/token-scopes');
    }
}
