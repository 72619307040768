import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { AdminUserRequest, User } from '@ncnp-mono/generics';

@Register()
export class AdminUserService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public getAdminUsers(): Promise<User[]> {
        return this.httpService.get('api/v1/admin-users');
    }

    public createAdminUser(adminUserRequest: AdminUserRequest): Promise<User> {
        return this.httpService.post('api/v1/admin-users', adminUserRequest);
    }
}
