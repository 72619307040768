import Component from "vue-class-component";
import html from "./file-preview.component.html";
import Vue from "vue";
import { type Dossier, DossierFile} from "@ncnp-mono/generics";
import {Inject, Prop} from "vue-property-decorator";
import {DossierFileService} from "@ncnp-mono/utils";

@Component({
    template: html,
})
export class FilePreviewComponent extends Vue {

    @Prop()
    dossier!: Dossier;

    @Inject()
    dossierFileService!: DossierFileService;

    public loading = false;
    public activeFile?: DossierFile | null = null;
    public previewObjectUrl: string | null = null;

    async beforeMount() {
        await this.loadPreview();
    }

    private async loadPreview(): Promise<void> {
        if (this.dossier?.files) {
            this.activeFile = this.getPreviewFile(this.dossier.files);

            if (this.activeFile) {
                this.loading = true;

                await this.dossierFileService.getDossierFile(this.activeFile)
                    .then(blob => this.previewObjectUrl = URL.createObjectURL(blob))
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    }

    public onFilePreviewChange(): void {
        this.loading = true;
    }

    private getPreviewFile(files: DossierFile[]): DossierFile | undefined {
        return files.find(file => file.mimetype === "pdf")
            ?? files.find(file => ["png", "jpg"].includes(file.mimetype));
    }

    get isPDF(): boolean {
        return this.activeFile?.mimetype.includes("pdf") || false;
    }

    get isImage(): boolean {
        return ["png", "jpg"].includes(this.activeFile?.mimetype || "") || false;
    }
}
