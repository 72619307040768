export enum FileTypesEnum {
    ZIP = 'application/zip',
    XZIP = 'application/x-zip',
    XZIPCOMPRESSED = 'application/x-zip-compressed',
    XCOMPRESS = 'application/x-compress',
    XCOMPRESSED = 'application/x-compressed',
    MULTIXZIP = 'multipart/x-zip',
    CSV = 'text/csv',
    JPEG = 'image/jpeg',
    PNG = 'image/png',
    GIF = 'image/gif',
    PDF = 'application/pdf',
    TXT = 'text/plain',
    DOC = 'application/msword',
    DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    XLS = 'application/vnd.ms-excel',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}
