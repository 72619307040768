import Component from "vue-class-component";
import Vue from "vue";
import html from "./step-three.component.html";
import {Inject, Prop} from "vue-property-decorator";
import type {BulkAuthorizeClaimsRequest, Dossier, DossierRequest} from "@ncnp-mono/generics";
import {ClaimService, DossierService, formatAddress, formatDate} from "@ncnp-mono/utils";
import {DataTableHeader} from "vuetify";
import { Claim, StateClaimRequest, ClaimStateEnum } from "@ncnp-mono/generics";
import {ConfirmDialog} from "@ncnp-mono/components";
import {RejectClaimDialog} from "../../../../dialogs/reject-claim.dialog";

@Component({
    template: html,
    methods: {
        formatAddress,
    }
})
export class StepThreeComponent extends Vue {

    @Prop()
    isFormValid!: boolean;

    @Prop()
    dossierRequest!: DossierRequest;

    @Prop()
    dossier!: Dossier;

    @Inject()
    dossierService!: DossierService;

    @Inject()
    claimService!: ClaimService;

    public claimHeaders: DataTableHeader[] = [
        {
            text: 'Adres',
            value: 'address',
            sortable: false,
        },
        {
            text: 'Postcode',
            value: 'zipCode',
            sortable: false,
        },
        {
            text: 'Plaats',
            value: 'city',
            sortable: false,
        },
        {
            text: 'Acties',
            value: 'actions',
            sortable: false,
        },
    ];

    public selected = [];

    public stateClaimRequest: StateClaimRequest = { state: ClaimStateEnum.AUTHORIZECLAIM };

    public filterActiveClaims(claims: Claim[]): Claim[] {
        return claims.filter((claim) => this.isActiveClaim(claim));
    }

    private isActiveClaim(claim: Claim): boolean {
        return !claim.revokedAt && !claim.authorizedAt;
    }

    private rejectClaim(claim: Claim): void {
        this.$vueDialogs.open(RejectClaimDialog, {
            propsData: {
                claim: claim
            },
            dialogSettings: {
                maxWidth: '728px',
            },
            // @TODO: table refresh fixen?
            // }).result.observe(this.getDossier);
        });
    }

    private authorizeClaim(claim: Claim): void {
        this.$vueDialogs.open(ConfirmDialog, {
            propsData: {
                message: 'Weet u zeker dat u deze aanslagregel wilt toekennen?',
                confirmButtonText: 'Bevestigen',
            },
            dialogSettings: {
                maxWidth: '728px',
            },
        }).onConfirm.observe(() => this.handleAuthorizedClaim(claim));
    }

    private async handleAuthorizedClaim(claim: Claim): Promise<void> {
        try {
            await this.claimService.updateClaim(claim.id, this.stateClaimRequest);

            this.$snackbar.success('De aanslagregel is toegekend');
        } catch(e) {
            this.$snackbar.danger('Er is een fout opgetreden bij het toekennen van de aanslagregel');
        }
    }

    public async authorizeSelectedClaims(claims: Claim[]): Promise<void> {
        const request: BulkAuthorizeClaimsRequest = {
            claimIds: claims.map(claim => claim.id)
        }

        try {
            await this.claimService.bulkAuthorizeClaims(this.dossier.id, request);

            // @TODO: write logic in active branch NGB-122 for updating claims in table once they are authorized

            this.$snackbar.success('De aanslagregels zijn toegekend');
        } catch(e) {
            this.$snackbar.danger('Er is een fout opgetreden bij het toekennen van één of meerdere aanslagregels');
        }
    }

    get dossierDate() {
        const date = this.dossier?.createdAt ?? new Date();

        return formatDate(date);
    }
}
