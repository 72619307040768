import { DirectiveOptions } from 'vue';
import { email, required } from '@bakerware/vue-validation-rules';
import { resolve } from '@bakerware/vue-utils';
import { UserService } from '../services';

export const CheckEmailExistsDirective: DirectiveOptions = {
    bind: (el, binding, vnode) => {
        const userService = resolve(UserService);
        const emailRules = [ required, email, ];

        const inputField = el.querySelector('input');

        if (inputField) {
            inputField.addEventListener('blur', async () => {
                if (emailRules.reduce(
                    (invalid, rule) => invalid || rule(inputField.value) !== true, false)
                ) {
                    return;
                }

                (vnode.componentInstance?.$data as any).errorBucket = [];

                const userExists = await userService.checkEmailExists(inputField.value);

                if (userExists) {
                    (vnode.componentInstance?.$data as any).errorBucket.push('E-mail bestaat al');
                }
            });
        }
    },
};
