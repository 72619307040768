import Vue from 'vue';
import Component from 'vue-class-component';
import html from './user-visual-settings.component.html';
import { useVisualSettingsStore } from '@ncnp-mono/utils';

@Component({
    template: html,
})
export class UserVisualSettingsComponent extends Vue {
    public visualSettingsStore = useVisualSettingsStore();

    get darkMode(): boolean {
        return this.visualSettingsStore.darkMode;
    }

    set darkMode(state: boolean) {
        this.visualSettingsStore.setDarkMode(this.$auth.user?.id, state).then(state => this.$vuetify.theme.dark = state);
    }

    get stripedTables(): boolean {
        return this.visualSettingsStore.stripedTables;
    }

    set stripedTables(state: boolean) {
        this.visualSettingsStore.setStripedTables(this.$auth.user?.id, state);
    }
}
