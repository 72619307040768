import { AuditEntity } from '@ncnp-mono/generics';
import { HttpService } from '@bakerware/http';
import { Register } from '@bakerware/vue-utils';

@Register()
export class AuditService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public getEntities(): Promise<AuditEntity[]> {
        return this.httpService.get('api/v1/audits/entities');
    }

    public getPaginatedLogs(entity: string, page: number, limit: number): Promise<AuditEntity> {
        return this.httpService.get('api/v1/audits/logs', {
            entity: entity,
            page: page,
            limit: limit,
        });
    }
}
