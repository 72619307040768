import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { OrganisationUser, OrganisationUserRequest } from '@ncnp-mono/generics';

@Register()
export class OrganisationUserService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public createOrganisationUser(organisationId: number, organisationUserRequest: OrganisationUserRequest): Promise<OrganisationUser> {
        return this.httpService.post(`api/v1/organisations/${organisationId}/users`, organisationUserRequest);
    }

    public updateOrganisationUser(organisationId: number, userId: number, organisationUserRequest: OrganisationUserRequest): Promise<OrganisationUser> {
        return this.httpService.put(`api/v1/organisations/${organisationId}/users/${userId}`, organisationUserRequest);
    }

    public getAllOrganisationUsers(organisationId: number): Promise<OrganisationUser[]> {
        return this.httpService.get(`api/v1/organisations/${organisationId}/users`);
    }
}
