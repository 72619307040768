import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { CsvValidationResponse } from '@ncnp-mono/generics';

@Register()
export class FileService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public validateCsv(data: FormData): Promise<CsvValidationResponse> {
        return this.httpService.upload(`api/v1/dossiers/bulk/validate`, data);
    }
}
