import Component from "vue-class-component";
import html from "./step-one.component.html";
import { FileUploadComponent } from "@ncnp-mono/components";
import Vue from "vue";
import {type DossierRequest, type Organisation} from "@ncnp-mono/generics";
import {Inject, Prop} from "vue-property-decorator";
import {TaxOfficeOrganisationService} from "@ncnp-mono/utils";
import {required} from "@bakerware/vue-validation-rules";
import {CreateDossierMimeTypes} from "../../../../config";

@Component({
    template: html,
    components: {
        FileUploadComponent,
    },
})
export class StepOneComponent extends Vue {

    @Prop()
    dossierRequest!: DossierRequest;

    @Inject()
    taxOfficeOrganisationService!: TaxOfficeOrganisationService;

    public organisations: Organisation[] = [];

    public rules = {
        organisation: [required],
    };

    public files: File[] = [];
    public allowedMimetypes = CreateDossierMimeTypes;

    async beforeMount(): Promise<void> {
        await this.taxOfficeOrganisationService.getAllTaxOfficeOrganisations()
            .then(response => this.organisations = response)
            .catch(() => this.$snackbar.danger('Het ophalen van de gekoppelde organisaties is mislukt'));
    }

    public updateFiles(files: File[]): void {
        this.files = files;
    }

    public replaceFile(): void {
        this.$snackbar.success("Bestand(en) succesvol vervangen");
    }
}
