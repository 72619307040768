import Vue from 'vue';

export const EventBus = new Vue();

export function initials(name: string): string {
    // name has one or more spaces
    if (/\s/.test(name)) {
        return name.replace(/[\W_]+/g, '')[0] +
            //@ts-ignore
            name.split(' ').pop().replace(/[\W_]+/g, '').charAt(0);
    }

    return name.replace(/[\W_]+/g, '')[0];
}


export function backend(): string | undefined {
    return process.env.VUE_APP_BACKEND;
}

export function getImage(logo: string, mimeType: string): string {
    return `data:${mimeType};base64,${logo}`;
}

export function formatAddress(street: string, number: number, numberLetter: string, numberSuffix: string): string {
    let address = street ?? '';

    if (number) {
        address += ` ${number}`;
    }

    if (numberLetter) {
        address += number ? '' : ' ';
        address += numberLetter;
    }

    if (numberSuffix) {
        address += ` ${numberSuffix}`;
    }

    return address.trim();
}

export function isTestEnvironment(): boolean {
    return process.env.VUE_APP_ENVIRONMENT === 'DEV' || process.env.VUE_APP_ENVIRONMENT === 'TEST';
}

export function getTestEnvironmentText(): string {
    return 'Testomgeving';
}

