import Vue from "vue";
import Component from "vue-class-component";
import html from "./tax-office-general-data.component.html";
import {Inject} from "vue-property-decorator";
import {TaxOfficeService} from "@ncnp-mono/utils";
import {Address, TaxOffice} from "@ncnp-mono/generics";
import {TaxOfficeEditDialog} from "./dialogs/tax-office-edit.dialog";
import {TaxOfficeAddressEditDialog} from "./dialogs/tax-office-address-edit.dialog";
import {TaxOfficeAddressCreateDialog} from "./dialogs/tax-office-address-create.dialog";
import {UploadLogoDialog} from "./dialogs/upload-logo.dialog";

@Component({
    template: html,
})
export class TaxOfficeGeneralDataComponent extends Vue {

    @Inject()
    private taxOfficeService!: TaxOfficeService;

    public taxOffice: TaxOffice | null = null;

    beforeMount(): void {
        this.getTaxOffice();
    }

    private async getTaxOffice(): Promise<void> {
        this.taxOffice = await this.taxOfficeService.getTaxOfficeForUser();
    }

    public openTaxOfficeEditDialog(): void {
        this.$vueDialogs.open(TaxOfficeEditDialog, {
            propsData: {
                taxOffice: this.taxOffice,
            },
            dialogSettings: {
                maxWidth: '800px',
            },
        }).result.observe(this.updateTaxOffice);
    }

    public updateTaxOffice(taxOffice: TaxOffice): void {
        this.taxOffice = taxOffice;
    }

    public openAddressCreateDialog(): void {
        this.$vueDialogs.open(
            TaxOfficeAddressCreateDialog,
            {
                propsData: {
                    taxOfficeId: this.taxOffice?.id,
                    addresses: this.taxOffice?.addresses,
                },
                dialogSettings: {
                    maxWidth: '800px',
                },
            }
        ).result.observe(this.createTaxOfficeAddress);
    }

    public openAddressEditDialog(address: Address): void {
        this.$vueDialogs.open(
            TaxOfficeAddressEditDialog,
            {
                propsData: {
                    address,
                    addresses: this.taxOffice?.addresses,
                },
                dialogSettings: {
                    maxWidth: '800px',
                },
            }
        ).result.observe((_address?: Address | null) =>
            _address ? this.updateTaxOfficeAddress(_address) : this.deleteTaxOfficeAddress(address)
        );
    }

    public openTaxOfficeUploadLogoDialog(): void {
        this.$vueDialogs.open(UploadLogoDialog, {
            propsData: {
                taxOfficeId: this.taxOffice?.id,
            },
            dialogSettings: {
                maxWidth: '800px',
            },
        }).result.observe(this.updateTaxOfficeLogo);
    }

    public updateTaxOfficeLogo(logo: string): void {
        if (this.taxOffice) {
            this.taxOffice.logo = logo;
        }
    }

    public getImage(logo: string, mimeType: string): string {
        return `data:${mimeType};base64,${logo}`;
    }

    public createTaxOfficeAddress(address: Address): void {
        this.taxOffice?.addresses.push(address);
    }

    public updateTaxOfficeAddress(address: Address): void {
        const index = this.taxOffice?.addresses.findIndex(
            (_address: Address) => _address.id === address.id
        );

        if (index) {
            this.taxOffice?.addresses.splice(index, 1, address);
        }
    }

    public deleteTaxOfficeAddress(address: Address): void {
        const index = this.taxOffice?.addresses.findIndex(
            (_address: Address) => _address.id === address.id
        );

        if (index) {
            this.taxOffice?.addresses.splice(index, 1);
        }
    }
}
