import { DataTableHeader } from 'vuetify';

export const DossiersOverviewHeaders: DataTableHeader[] = [
    {
        text: 'Indiener',
        value: 'organisation.name',
        sortable: true,
    },
    {
        text: 'Subjectnummer',
        value: 'subjectNumber',
        sortable: false,
    },
    {
        text: 'Adres',
        value: 'address',
        sortable: false,
    },
    {
        text: 'Vorderingsnummer',
        value: 'claimNumber',
        sortable: true,
    },
    {
        text: 'Heffingsjaar',
        value: 'taxYear',
        sortable: true,
    },
    {
        text: 'Datum bezwaar',
        value: 'createdAt',
        sortable: true,
    },
    {
        text: 'Datum gemachtigd',
        value: 'authorizedAt',
        sortable: true,
    },
    {
        text: 'Actie',
        value: 'action',
        sortable: false,
    },
];
