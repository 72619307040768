import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { BulkBatch, BulkBatchRow, BulkBatchRowFile, BulkUploadRequest } from '@ncnp-mono/generics';
import fileDownload from 'js-file-download';
import { RemoveMissingFileRequest } from '@ncnp-mono/generics';

@Register()
export class BulkBatchService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public deleteBulkBatch(bulkBatchId: number): Promise<boolean> {
        return this.httpService.delete(`api/v1/bulk-batch/${bulkBatchId}`);
    }

    public deleteBulkBatchRow(bulkBatchRowId: number): Promise<boolean> {
        return this.httpService.delete(`api/v1/bulk-batch-row/${bulkBatchRowId}`);
    }

    public processBulkBatch(bulkBatchId: number): Promise<BulkBatch> {
        return this.httpService.post(`api/v1/bulk-batch/${bulkBatchId}/process`, null);
    }

    public addBulkBatchRowFile(bulkBatchRowId: number, formData: FormData): Promise<BulkBatchRowFile> {
        return this.httpService.post(`api/v1/bulk-batch-row-file/${bulkBatchRowId}`, formData);
    }

    public deleteBulkBatchRowFile(bulkBatchRowFileId: number): Promise<boolean> {
        return this.httpService.delete(`api/v1/bulk-batch-row-file/${bulkBatchRowFileId}`);
    }

    public getBulkBatch(bulkBatchId: string): Promise<BulkBatch> {
        return this.httpService.get(`api/v1/bulk-batch/${bulkBatchId}`);
    }

    public getBulkBatches(params: {status?: string} = {}): Promise<BulkBatch[]> {
        params = {
            status: 'CREATED',
            ...params,
        };

        return this.httpService.get(`api/v1/bulk-batch`, params);
    }

    public createBulkBatch(bulkUploadRequest: BulkUploadRequest): Promise<BulkBatch> {
        return this.httpService.post(`api/v1/bulk-batch`, bulkUploadRequest);
    }
    
    public downloadBulkBatchFile(bulkBatchRowFile: BulkBatchRowFile): Promise<void>  {
        return this.httpService.download<Blob>(`api/v1/bulk-batch-row-file/${bulkBatchRowFile.id}`, 'GET', bulkBatchRowFile.mimeType)
            .then((blob) => {
                fileDownload(blob, bulkBatchRowFile.fileName, bulkBatchRowFile.mimeType);
            });
    }

    public removeMissingFile(bulkBatchRowId: number, removeMissingFileRequest: RemoveMissingFileRequest): Promise<BulkBatchRow> {
        return this.httpService.delete(
            `api/v1/bulk-batch-row/${bulkBatchRowId}/remove-missing-file`,
            removeMissingFileRequest
        );
    }
}
