import Vue from "vue";
import Component from 'vue-class-component';
import html from './dossiers-overview.component.html';
import {Inject, Watch} from "vue-property-decorator";
import {DataOptions, DataTableHeader} from "vuetify";
import {ChangesOrder, Dossier, FiltersDossiers, PaginatesResults} from "@ncnp-mono/generics";
import {formatAddress, formatDate, TaxOfficeDossierService} from "@ncnp-mono/utils";
import {DossiersOverviewHeaders, PaginationFooterSettings} from "../../config";
import { DatePicker } from '@bakerware/vuetify-date-picker';


@Component({
    template: html,
    filters: {
        date: formatDate,
    },
    components: {
        DatePicker,
    },
    methods: {
        formatAddress,
    }
})

export class DossiersOverviewComponent extends Vue {

    @Inject()
    private taxOfficeDossierService!: TaxOfficeDossierService;

    @Watch('searchTerm')
    onSearchTermChange(): void {
        clearTimeout(this.timerId);
        this.timerId = window.setTimeout(this.getDossiers, 300);
    }

    @Watch('filter', { deep: true })
    @Watch('options', { deep: true })
    onFilterChange = () => this.getDossiers();

    public loading = true;
    public loadingText = 'De bezwaren worden opgehaald. Een ogenblik geduld.';
    public timerId?: number;
    public searchTerm = '';

    public filter: FiltersDossiers = {
        showAuthorized: false,
        showNotAuthorized: true,
        startDate: null,
        endDate: null,
    };

    public headers: DataTableHeader[] = DossiersOverviewHeaders;
    public footerSettings = PaginationFooterSettings;
    public options: DataOptions | null = null;
    public datePickerOptions = {
        filled: false,
        flat: true,
        dense: false,
        hideDetails: true,
    };

    public dossiers: Dossier[] = [];
    public totalDossiers = 0;

    private async getDossiers(): Promise<void> {
        this.loading = true;

        const paginatorRequest: PaginatesResults & ChangesOrder & FiltersDossiers = {
            ...this.filter,
            searchTerm: this.searchTerm,
            page: this.options?.page ?? 1,
            limit: this.options?.itemsPerPage ?? 10,
            orderBy: this.options?.sortBy[0],
            orderDirection: this.options?.sortDesc[0] ? 'DESC' : 'ASC',
        };

        this.taxOfficeDossierService.getTaxOfficeDossiers(paginatorRequest)
            .then((response) => {
                this.dossiers = response.data;
                this.totalDossiers = response.meta.total;
            })
            .catch(() => {
                this.$snackbar.danger('Het laden van de bezwaren is niet gelukt');
            })
            .finally(() => this.loading = false);
    }

    private viewClickedRow(dossier: Dossier): void {
        this.$router.push({
            name: 'dossier-details',
            params: {
                dossierId: dossier.id.toString(),
            },
        });
    }

    private submit(dossier: Dossier): void {
        this.$router.push({
            name: 'dossier-create',
            params: {
                dossierId: dossier ? dossier.id : ''
            }
        });
    }
}
