import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import { DossierService } from '@ncnp-mono/utils';

@Component
export class InteractsWithActionsDocumentsMixin extends Vue {

    @Inject()
    public dossierService!: DossierService;

    public async downloadDeliveryNotification(event: { id: string, name: string }): Promise<void> {
        try {
            await this.dossierService.downloadDeliveryNotification(event.id, event.name);
        } catch (e) {
            this.$snackbar.danger('Er is een fout opgetreden bij het downloaden van de ontvangstbevestiging');
        }
    }
}
