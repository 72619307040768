import Component, {mixins} from 'vue-class-component';
import html from './dossier-details.component.html';
import {Inject, Prop} from "vue-property-decorator";
import { ClaimService, DossierService, formatDate, formatAddress } from "@ncnp-mono/utils";
import {
    ActionDocumentsTableComponent,
    DossierDataComponent,
    DossierFilesTableComponent,
    InteractsWithActionsDocumentsMixin,
    InteractsWithDossierFilesMixin,
    SubjectDataComponent
} from "@ncnp-mono/components";
import {Action, Claim, ClaimStatusEnum, Dossier, DossierFile, ObjectTypesEnum} from "@ncnp-mono/generics";
import { ClaimsOverviewHeaders } from "../../config";
import {DataTableHeader} from "vuetify";

@Component({
    template: html,
    components: {
        ActionDocumentsTableComponent,
        DossierDataComponent,
        DossierFilesTableComponent,
        SubjectDataComponent,
    },
    filters: {
        date: formatDate,
    },
    methods: {
        formatAddress,
    },
})
export class DossierDetailsComponent extends mixins(
    InteractsWithActionsDocumentsMixin,
    InteractsWithDossierFilesMixin
) {
    @Prop()
    public dossierId!: string;

    @Inject()
    public claimService!: ClaimService;

    @Inject()
    public dossierService!: DossierService;

    public dossier: Dossier | null = null;
    public claimsOverviewHeaders: DataTableHeader[] = ClaimsOverviewHeaders;

    public activeTab = 0;

    get dossierActions(): Action[] {
        if (this.dossier?.actions) {
            return this.dossier.actions;
        }

        return [];
    }

    get dossierFiles(): DossierFile[] {
        if (this.dossier?.files) {
            return this.dossier.files;
        }

        return [];
    }

    beforeMount(): void {
        this.getDossier();
    }

    get filteredClaimHeaders(): DataTableHeader[] {
        const headers = this.claimsOverviewHeaders;

        if (this.dossier && !this.isWozObject(this.dossier.claims[0].objectType.code)) {
            const index = headers.findIndex(header => header.value === 'objectNumber');

            if (index > -1) {
                headers.splice(index, 1);
            }
        }

        return headers;
    }

    private async getDossier(): Promise<void> {
        await this.dossierService.getDossier(this.dossierId)
            .then((response) => this.dossier = response)
            .catch(() => this.$snackbar.danger('Het ophalen van het bezwaar is mislukt'));
    }

    private async downloadTaxReport(claim: Claim): Promise<void> {
        try {
            await this.claimService.downloadTaxReport(claim.id);
        } catch (e) {
            this.$snackbar.danger(
                'Er is een fout opgetreden bij het downloaden van het taxatieverslag'
            );
        }
    }

    private getClaimStatus(claim: Claim): string {
        let status = '';

        if (claim.reasonNotAuthorized) {
            status = ClaimStatusEnum.REJECTED;
        } else if (claim.revokedAt) {
            status = ClaimStatusEnum.WITHDRAWN;
        } else if (claim.authorizedAt) {
            status = ClaimStatusEnum.AUTHORIZED;
        }

        return status;
    }

    private hasTaxReportDownload(claim: Claim): boolean {
        return claim.authorizedAt && this.isWozObject(claim.objectType.code);
    }

    private isWozObject(code: string): boolean {
        return code === ObjectTypesEnum.WOZ;
    }

    public filterActiveClaims(claims: Claim[]): Claim[] {
        return claims?.filter((claim) => this.isActiveClaim(claim)) || [];
    }

    private isActiveClaim(claim: Claim): boolean {
        return !!(claim.authorizedAt || claim.reasonNotAuthorized);
    }
}
