import Component from 'vue-class-component';
import { Inject, Ref } from 'vue-property-decorator';
import html from './organisation-create.dialog.html';
import type { VForm} from "@bakerware/vue-utils";
import { IsDialogMixin, Observable } from "@bakerware/vue-dialogs";
import { OrganisationService } from "@ncnp-mono/utils";
import { Organisation } from "@ncnp-mono/generics";
import { OrganisationCreateRequest } from "@ncnp-mono/generics";
import {OrganisationRules} from "../config/organisation.rules";

@Component({
    template: html,
})
export class OrganisationCreateDialog extends IsDialogMixin {
    @Inject()
    public organisationService!: OrganisationService;

    @Ref('form')
    public form!: VForm;

    public result = new Observable<Organisation>();
    public organisationCreateRequest: OrganisationCreateRequest = {};

    public kvkBranchNumberMessage = '';
    private rules = OrganisationRules;

    async submit(): Promise<void> {
        if (this.form.validate()) {
            try {
                const organisation =
                    await this.organisationService.createOrganisation(
                        this.organisationCreateRequest
                    );

                this.result.emit(organisation);
                this.$snackbar.success('Aanmaken organisatie gelukt');
                this.activeDialog.close();
            } catch (e) {
                this.$snackbar.danger('Aanmaken organisatie mislukt');
            }
        }
    }

    async checkKvkBranchNumberExists(): Promise<void> {
        this.clearErrors();

        const isKvkValid = this.rules.kvk.reduce(
            (valid, rule) =>
                !valid ? false : rule(this.organisationCreateRequest.kvk) === true,
            true
        );

        if (!isKvkValid) {
            return;
        }

        const isBranchNumberValid = this.rules.branchNumber.reduce(
            (valid, rule) =>
                !valid
                    ? false
                    : rule(this.organisationCreateRequest.branchNumber) === true,
            true
        );

        if (!isBranchNumberValid) {
            return;
        }

        const organisationExists =
            await this.organisationService.checkKvkBranchNumberExists(
                this.organisationCreateRequest
            );

        if (organisationExists) {
            this.kvkBranchNumberMessage = 'KVK en vestigingsnummer bestaan al';
        }
    }

    clearErrors(): void {
        this.kvkBranchNumberMessage = '';
    }
}
