import {RouteConfigSingleView} from "vue-router/types/router";
import {TaxOfficeGeneralDataComponent} from "./pages/general-data/tax-office-general-data.component";

export const TaxOfficeRoutingModule: RouteConfigSingleView[] = [
    {
        path: 'mijn-organisatie',
        name: 'tax-office-general-data',
        component: TaxOfficeGeneralDataComponent,
        meta: {
            requiresAuth: true,
            breadcrumb: {
                title: 'Mijn organisatie'
            }
        }
    },
];
