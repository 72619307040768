import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { BulkTaxReportsRequest, BulkTaxReportsResponse, TaxReportsArchiveFile } from '@ncnp-mono/generics';
import fileDownload from 'js-file-download';

@Register()
export class BulkTaxReportsService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public createAsynchronousTaxReports(bulkTaxReportsRequest: BulkTaxReportsRequest): Promise<BulkTaxReportsResponse> {
        return this.httpService.post(`api/v1/bulk-tax-reports`, bulkTaxReportsRequest);
    }

    public getArchiveFile(archiveFileId: string): Promise<TaxReportsArchiveFile> {
        return this.httpService.get(`api/v1/bulk-tax-reports/${archiveFileId}`);
    }

    public downloadArchiveFile(file: TaxReportsArchiveFile): Promise<Blob|void> {
        return this.httpService.download<Blob>(
            `api/v1/bulk-tax-reports/${file.id}/download`,
            'GET',
            file.mimetype
        ).then(blob => fileDownload(blob, file.fileName, file.mimetype));
    }
}
