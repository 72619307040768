import Vue from 'vue';
import BakerwareSnackbar from "@bakerware/vuetify-snackbar";
import VueDialogs from "@bakerware/vue-dialogs";
import Vuetify from "vuetify";
import BakerwareAuth from "@bakerware/vue-authentication-module";
import {bakerwareAuthSettings, vuetifySettings} from "../../configs";

Vue.use(Vuetify, {iconfont: 'mdi',});
Vue.use(VueDialogs);
Vue.use(BakerwareSnackbar);
Vue.use(BakerwareAuth, bakerwareAuthSettings);

export const vuetify = new Vuetify(vuetifySettings);
export const snackbar = new BakerwareSnackbar();
snackbar.snackbarSettings.timeout = 10000;
