import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { Address, AddressRequest } from '@ncnp-mono/generics';

@Register()
export class AddressService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public updateAddress(addressId: number, addressRequest: AddressRequest): Promise<Address> {
        return this.httpService.put(`api/v1/addresses/${addressId}`, addressRequest);
    }

    public deleteAddress(addressId: number): Promise<Address> {
        return this.httpService.delete(`api/v1/addresses/${addressId}`);
    }
}
