import Vue from 'vue';
import Component from 'vue-class-component';
import html from './dossier-data.component.html';
import { formatDate, getImage } from '@ncnp-mono/utils';
import { Prop } from 'vue-property-decorator';
import type { Dossier } from '@ncnp-mono/generics';

@Component({
    template: html,
    methods: {
        getImage,
    },
    filters: {
        date: formatDate,
    },
})

export class DossierDataComponent extends Vue {
    @Prop()
    public dossier!: Dossier;
}
