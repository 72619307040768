import {UserProfileComponent} from "./pages/profile/user-profile.component";

export const UserProfileRoutingModule = [
    {
        path: 'profiel',
        name: 'user-profile',
        component: UserProfileComponent,
        meta: {
            requiresAuth: true,
            breadcrumb: {
                title: 'Profiel',
            },
        },
    },
];
