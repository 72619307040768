import {TaxOfficeUsersOverviewComponent} from "./pages/overview/tax-office-users-overview.component";

export const TaxOfficeUserRoutingModule = [
    {
        path: 'gebruikers',
        name: 'tax-office-users-overview',
        component: TaxOfficeUsersOverviewComponent,
        meta: {
            requiresAuth: true,
            title: 'Gebruikers beheren',
            breadcrumb: {
                title: 'Gebruikers beheren',
            },
        },
    },
];
