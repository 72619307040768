import { AllowedMimeType, DossierFile } from '@ncnp-mono/generics';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import html from './file-upload.component.html';

@Component({
    template: html,
    model: {
        prop: 'uploadedFiles',
    },
})
export class FileUploadComponent extends Vue {

    @Prop({
        default: () => [],
    })
    readonlyFiles!: DossierFile[];

    @Prop({
        default: () => [],
    })
    allowedMimeTypes!: AllowedMimeType[];

    @Prop({
        default: () => [],
    })
    uploadedFiles!: File[];

    @Ref()
    public fileInput!: HTMLFormElement;

    @Ref()
    public form!: HTMLFormElement;

    public dragover = false;
    public loading = false;

    get fileNames() {
        return this.uploadedFiles.map(file => file.name);
    }

    public get distinctFileExtensions(): Set<string> {
        return new Set(this.allowedMimeTypes.map((mimeType) => mimeType.extension));
    }

    public openFileInput(): void {
        this.fileInput.click();
    }

    public onDrop(event: DragEvent) {
        this.dragover = false;

        Array.from(event.dataTransfer?.files as FileList)
            .forEach(file => this.addFile(file));

        this.$emit('update:files', this.uploadedFiles);

        this.clearForm();
    }

    public onFileInputChange(): void {
        Array.from(this.fileInput.files as FileList)
            .forEach(file => this.addFile(file));

        this.$emit('update:files', this.uploadedFiles);

        this.clearForm();
    }

    public clearForm() {
        // in some browsers, subsequent change events of the input element won't fire unless the form is cleared
        this.form.reset();
    }

    public onFilePreviewChange(): void {
        this.loading = true;
    }

    public deleteFile(fileName: string) {
        const index = this.uploadedFiles.findIndex(file => file.name === fileName);

        if (index > -1) {
            this.uploadedFiles.splice(index, 1);
        }

        this.$emit('update:files', this.uploadedFiles);
    }

    private getFileExtension(file: File): string {
        const segments = file.name.split('.');

        // in case the file has no extension
        if (segments.length === 1) {
            return '';
        }

        return segments.pop() ?? '';
    }

    private addFile(file: File): void {
        const fileExtension = this.getFileExtension(file);

        if (!this.checkMimeTypes(file)) {
            this.$snackbar.danger(`Bestandsformaat ${fileExtension.toUpperCase()} is niet toegestaan`);

            return;
        }

        if (this.hasFile(file)) {
            this.replaceFile(file);

            this.$emit('replace:file', this.uploadedFiles);

            return;
        }

        if (!this.checkCounts(file)) {
            this.$snackbar.danger(`Er is al een ${fileExtension.toUpperCase()} bestand toegevoegd`);

            return;
        }

        this.uploadedFiles.push(file);
    }

    private checkMimeTypes(file: File): boolean {
        return this.allowedMimeTypes.some(v => v.type === file.type);
    }

    private checkCounts(file: File): boolean {
        for (const mimeType of this.allowedMimeTypes) {
            if (mimeType.count) {
                if (file.type === mimeType.type) {
                    return this.uploadedFiles.filter(_file => _file.type === file.type).length < mimeType.count;
                }
            }
        }

        return true;
    }

    private hasFile(file: File): boolean {
        return this.uploadedFiles.some(_file => _file.name === file.name && _file.type === file.type);
    }

    private replaceFile(file: File): void {
        this.uploadedFiles[
            this.uploadedFiles.findIndex(_file => _file.name === file.name && _file.type === file.type)
        ] = file;
    }
}
