import Component from 'vue-class-component';
import Vue from "vue";
import html from './tax-office-users-overview.component.html';
import {UsersOverviewComponent} from "@ncnp-mono/components";
import {TaxOffice, User} from "@ncnp-mono/generics";
import {Inject} from "vue-property-decorator";
import {TaxOfficeService, TaxOfficeUserService} from "@ncnp-mono/utils";

@Component({
    template: html,
    components: {
        UsersOverviewComponent,
    }
})
export class TaxOfficeUsersOverviewComponent extends Vue {

    @Inject()
    taxOfficeUserService!: TaxOfficeUserService;

    @Inject()
    taxOfficeService!: TaxOfficeService;

    public taxOfficeUsers: User[] = [];
    public taxOffice!: TaxOffice;

    beforeMount(): void {
        this.getTaxOffice().then(() => this.getTaxOfficeUsers());
    }

    private async getTaxOffice(): Promise<void> {
        this.taxOffice = await this.taxOfficeService.getTaxOfficeForUser();
    }

    private async getTaxOfficeUsers(): Promise<void> {
        this.taxOfficeUsers = await this.taxOfficeUserService.getTaxOfficeUsers(this.taxOffice.id);
    }
}
