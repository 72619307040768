import { ChangesOrder,
    Collection,
    Organisation,
    OrganisationRequest,
    PaginatesResults } from '@ncnp-mono/generics';
import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';

@Register()
export class OrganisationService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public getOrganisation(organisationId: number): Promise<Organisation> {
        return this.httpService.get(`api/v1/organisations/${organisationId}`);
    }

    public getAllOrganisations(paginatorRequest: { searchTerm: string } & PaginatesResults & ChangesOrder): Promise<Collection<Organisation>> {
        return this.httpService.get('api/v1/organisations', paginatorRequest);
    }

    public getOrganisationForUser(): Promise<Organisation> {
        return this.httpService.get('api/v1/organisations/organisation-for-user');
    }

    public createOrganisation(organisationRequest: OrganisationRequest): Promise<Organisation> {
        return this.httpService.post('api/v1/organisations', organisationRequest);
    }

    public updateOrganisation(organisationId: number, organisationRequest: OrganisationRequest): Promise<Organisation> {
        return this.httpService.put(`api/v1/organisations/${organisationId}`, organisationRequest);
    }

    public checkKvkBranchNumberExists(organisationRequest: OrganisationRequest, organisationId: number | null = null): Promise<boolean> {
        return this.httpService.post( `api/v1/organisations/${organisationId}/check-kvk-branch-number-exists`, organisationRequest);
    }

    public updateSetting(organisationId: number, name: string, value: boolean): Promise<void> {
        return this.httpService.put(`api/v1/organisations/${organisationId}/settings`,{
            name: name,
            value: value,
        });
    }
}
