import { email, hasNoScriptTags, max, min, numeric, required } from "@bakerware/vue-validation-rules";

export const OrganisationRules = {
    name: [ required, max, hasNoScriptTags, ],
    kvk: [
        required,
        numeric,
        (v: unknown): string | boolean => min(v, 8),
        (v: unknown): string | boolean => max(v, 8),
    ],
    branchNumber: [
        required,
        numeric,
        (v: unknown): string | boolean => min(v, 12),
        (v: unknown): string | boolean => max(v, 12),
    ],
    email: [ required, email, ],
    phoneNumber: [ required, (v: unknown): string | boolean => max(v, 20), ],
    subjectNumber: [required, numeric, (v: number) => max(v, 10)],
};
