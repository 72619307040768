import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from './routes';
import { router, setLoginMessage } from "@ncnp-mono/utils";
import { isAuthenticatedGuard } from '@bakerware/vue-authentication-module';

Vue.use(VueRouter);

for (const route of routes) {
    router.addRoute(route);
}

router.beforeEach(isAuthenticatedGuard);
router.beforeEach(setLoginMessage);
