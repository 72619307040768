import { AxiosRequestConfig } from 'axios';
import { AuthService } from '@bakerware/vue-authentication-module';

export function authorizationHandler(request: AxiosRequestConfig): AxiosRequestConfig {
    const token = AuthService.instance.bearerToken;

    if (token) {
        request.headers = {
            ...request.headers,
            Authorization: `Bearer ${token}`,
        };
    }

    return request;
}
 
