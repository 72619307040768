import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { ChangesOrder,
    Collection,
    Dossier,
    FiltersDossiers,
    PaginatesResults } from '@ncnp-mono/generics';

@Register()
export class OrganisationDossierService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public getOrganisationDossiers(paginatorRequest: PaginatesResults & ChangesOrder & FiltersDossiers): Promise<Collection<Dossier>> {
        return this.httpService.post('api/v1/organisation-dossiers', paginatorRequest);
    }

    public getAvailableTaxYears(): Promise<number[]> {
        return this.httpService.get('api/v1/organisation-dossiers/tax-years');
    }
}
