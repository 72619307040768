import Vue from 'vue';
import html from './breadcrumb.component.html';
import Component from 'vue-class-component';
import { Breadcrumb } from '@ncnp-mono/generics';
import { Inject, Watch } from 'vue-property-decorator';
import { BreadcrumbService } from '@ncnp-mono/utils';

@Component({
    template: html,
})
export class BreadcrumbsComponent extends Vue {

    @Inject()
    private breadcrumbService!: BreadcrumbService;

    public breadcrumbs: Breadcrumb[] = [];

    private isObserving = false;

    @Watch('$route', {
        immediate: true,
        deep: true,
    })
    onRouteChange() {
        if (!this.isObserving) {
            this.breadcrumbService.breadcrumbs$.observe(breadcrumbs => this.breadcrumbs = breadcrumbs);
            this.isObserving = true;
        }

        const crumbs: Breadcrumb[] = [];

        this.$route.matched.forEach(route => {
            if (route.meta.breadcrumb) {
                crumbs.push({
                    name: route.name,
                    href: route.path,
                    to: {
                        name: route.meta?.breadcrumb?.to || route.name,
                        query: this.$route.query,
                    },
                    text: route.meta?.breadcrumb.title,
                    type: route.meta?.breadcrumb.type,
                });
            }
        });

        this.handleBreadcrumbsUpdate(crumbs);
    }
    
    private handleBreadcrumbsUpdate(crumbs: Breadcrumb[]): void {
        // Ignore single breadcrumb
        if (crumbs.length === 1) {
            this.breadcrumbs = [];

            return;
        }

        this.breadcrumbService.updateBreadcrumbs(crumbs);
    }
}
