import { OrganisationsOverviewComponent } from './pages/overview/organisations-overview.component';
import { RouteConfigSingleView } from 'vue-router/types/router';
import { OrganisationDetailsComponent } from "./pages/details/organisation-details.component";
import { OrganisationRouterViewComponent } from "./pages/router-view/organisation-router-view.component";

export const OrganisationRoutingModule: RouteConfigSingleView[] = [
    {
        path: 'organisaties',
        name: 'organisations-overview-container',
        component: OrganisationRouterViewComponent,
        meta: {
            requiresAuth: true,
            breadcrumb: {
                title: 'Organisaties',
                to: 'organisations-overview'
            }
        },
        children: [
            {
                path: 'overzicht',
                name: 'organisations-overview',
                component: OrganisationsOverviewComponent,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: ':organisationId',
                name: 'organisation-general-data',
                component: OrganisationDetailsComponent,
                props: true,
                meta: {
                    requiresAuth: true,
                    breadcrumb: {
                        title: 'Details',
                        type: 'resolver',
                    },
                },
            },
        ],
    },
];
