import Component from 'vue-class-component';
import { Inject, Prop, Ref } from 'vue-property-decorator';
import html from './upload-logo.dialog.html';
import { IsDialogMixin } from "@bakerware/vue-dialogs";
import { mimetypes, required } from "@bakerware/vue-validation-rules";
import { TaxOfficeService } from "@ncnp-mono/utils";
import {Observable} from "@bakerware/vue-utils";
import type {VForm} from "@bakerware/vue-utils";

@Component({
    template: html
})
export class UploadLogoDialog extends IsDialogMixin {

    @Inject()
    taxOfficeService!: TaxOfficeService;

    @Prop()
    taxOfficeId!: number;

    @Ref()
    form!: VForm;

    public result = new Observable<string>();

    uploadFile?: string | null = null;

    uploadFileRules = [
        required,
        (v: any) =>
            mimetypes(v, [
                'image/jpeg',
                'image/png',
                'image/gif',
                'image/svg+xml',
            ]),
        (v: any) =>
            !v || v.size < 2000000 || 'Bestand mag niet groter zijn dan 2 MB',
    ];

    async addFile(uploadFile: string): Promise<void> {
        if (!this.form.validate()) {
            return;
        }
        const formData = new FormData();
        formData.append('file', uploadFile);

        try {
            const taxOffice = await this.taxOfficeService.updateTaxOfficeLogo(
                this.taxOfficeId,
                formData
            );

            this.result.emit(taxOffice.logo);
            this.activeDialog.close();
        } catch {
            this.$snackbar.danger('Uploaden logo mislukt');
        }
    }
}
