import Vue from 'vue';
import Component from 'vue-class-component';
import { HeaderComponent } from '../header/header.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { FooterComponent } from '../footer/footer.component';
import html from './base.component.html';
import { BreadcrumbsComponent } from "@ncnp-mono/components";
import {EventBus, useVisualSettingsStore, VisualSettings} from "@ncnp-mono/utils";
import {User} from "@bakerware/vue-authentication-module/dist/generics/user.view";

@Component({
    components: {
        'breadcrumbs': BreadcrumbsComponent,
        'app-header': HeaderComponent,
        'app-sidebar': SidebarComponent,
        'app-footer': FooterComponent,
    },
    template: html,
})

export class BaseComponent extends Vue {

    public visualSettingsStore = useVisualSettingsStore();
    public user: User | null = this.$auth.user;

    private loadingQ: number[] = [];

    private async beforeMount(): Promise<void> {
        if (this.$route.path === '/') {
            await this.$router.push({ name: 'dashboard', });
        }

        this.setVisualSettings();
        this.events();
    }

    private events(): void {
        EventBus.$on('startRequest', () => this.loadingQ.push(1));
        EventBus.$on('endRequest', () => this.loadingQ.shift());
    }

    private setVisualSettings(): void {
        this.visualSettingsStore.init(<VisualSettings>this.user?.userSettings?.visual);
        this.$vuetify.theme.dark = this.visualSettingsStore.darkMode;
    }

    get isLoading(): boolean {
        return this.loadingQ.length > 0;
    }

    get settings() {
        return {
            stripedTables: this.visualSettingsStore.stripedTables,
        };
    }
}
