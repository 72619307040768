import Vue from "vue";
import Component from "vue-class-component";
import html from "./create-dossier.component.html";
import type { VForm } from "@bakerware/vue-utils";
import { Inject, Ref } from "vue-property-decorator";
import { DossierService } from "@ncnp-mono/utils";
import { Dossier, DossierRequest } from "@ncnp-mono/generics";
import { FilePreviewComponent } from "./file-preview/file-preview.component";
import { StepOneComponent } from "./stepper/step-one/step-one.component";
import { StepTwoComponent } from "./stepper/step-two/step-two.component";
import { StepThreeComponent } from "./stepper/step-three/step-three.component";

@Component({
    template: html,
    components: {
        FilePreviewComponent,
        StepOneComponent,
        StepTwoComponent,
        StepThreeComponent,
    },
})
export class CreateDossierComponent extends Vue {

    @Inject()
    dossierService!: DossierService;

    @Ref()
    public form!: VForm;

    public dossierRequest: DossierRequest = {
        claims: [],
        files: [],
    };

    public dossier?: Dossier | null = null;
    public dossierId!: string | null;

    public isFormValid = false;
    public step = 1;

    beforeMount(): void {
        if (this.isPreExistingDossier) {
            this.dossierId = this.$route.params.dossierId;
            this.step = 2;
            this.getDossier(this.dossierId);
        }
    }

    private getDossier(dossierId: string): void  {
        this.dossierService.getDossier(dossierId)
            .then((response) => {
                this.dossierRequest = Object.assign({}, this.dossierRequest, response);

                this.dossier = response;
            })
            .catch(() => this.$snackbar.danger('Het ophalen van de aanslag is mislukt'));
    }

    private async createDossier(): Promise<void> {
        await this.dossierService.createDossier(this.dossierRequest).then(response => this.dossierId = response.dossierId);
    }

    private completeFirstStep(formData: DossierRequest): void {
        this.appendFormData(formData);

        if (this.dossierId) {
            this.nextStep();
        } else {
            this.createDossier()
                .then(() => this.nextStep())
                .catch(() => this.$snackbar.danger('Er is een onbekende fout opgetreden'));
        }
    }

    public completeLastStep(): void {
        if (this.dossierId) {
            this.dossierService.updateDossier(this.dossierId, this.dossierRequest)
                .then(() => {
                    this.$snackbar.success(
                        this.isPreExistingDossier
                            ? 'Het bezwaar is succesvol bijgewerkt'
                            : 'Het bezwaar is succesvol aangemaakt'
                    );

                    this.$router.push({
                        name: 'dossiers-overview',
                    });
                })
                .catch(() => {
                    this.$snackbar.danger(
                        this.isPreExistingDossier
                            ? 'Er is een fout opgetreden bij het bijwerken van het bezwaar'
                            : 'Er is een fout opgetreden bij het aanmaken van het bezwaar'
                    );
                });
        }
    }

    private appendFormData(formData: DossierRequest): void {
        this.dossierRequest = Object.assign({}, this.dossierRequest, formData);
    }

    private validateInput(formData: DossierRequest): void {
        if (!this.form.validate()) {
            return;
        }

        this.appendFormData(formData);

        this.nextStep();
    }

    private previousStep(): void {
        this.step--;
    }

    private nextStep(): void {
        this.step++;
    }

    private get isPreExistingDossier(): boolean {
        return 'dossierId' in this.$route.params;
    }
}
