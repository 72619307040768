import { HttpService } from '@bakerware/http';
import { backend, EventBus } from '../helpers';
import { unauthorizedHandler } from './interceptors/unauthorized.handler';
import { authorizationHandler } from './interceptors/authorization.handler';

export const httpService = new HttpService({
    baseURL: backend(),
});

httpService.axios.interceptors.response.use(response => response, unauthorizedHandler);
httpService.axios.interceptors.request.use(authorizationHandler);

httpService.onRequestStart(() => EventBus.$emit('startRequest'));
httpService.onRequestEnd(() => EventBus.$emit('endRequest'));
