import Vue from 'vue';
import Component from 'vue-class-component';
import html from './dossier-files-table.component.html';
import { formatDate } from '@ncnp-mono/utils';
import { Prop } from 'vue-property-decorator';
import { DossierFile } from '@ncnp-mono/generics';
import { DataTableHeader } from 'vuetify';
import { DossierFilesOverviewHeaders } from '../../config';

@Component({
    template: html,
    filters: {
        date: formatDate,
    },
})
export class DossierFilesTableComponent extends Vue {
    @Prop({
        default: (): DossierFile[] => [],
    })
    public dossierFiles!: DossierFile[];

    @Prop({
        default: () => false,
    })
    public hasDownload!: boolean;
    
    public dossierFilesOverviewHeaders: DataTableHeader[] = DossierFilesOverviewHeaders;

    public downloadFile(id: number, name: string, mimeType: string): void {
        this.$emit('downloadFile', {
            id: id,
            name: name,
            mimeType: mimeType,
        });
    }
}
