import {Exception} from '@bakerware/http';
import {router} from "@ncnp-mono/utils";

export function unauthorizedHandler(error: Exception) {
    if (error.httpResponseCode === 401) {
        router.push({
            name: 'login',
        });
    }
    return Promise.reject(error);
} 
