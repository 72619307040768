import Component from 'vue-class-component';
import { Inject, Prop, Ref } from 'vue-property-decorator';
import html from './organisation-address-edit.dialog.html';
import {IsDialogMixin} from "@bakerware/vue-dialogs";
import {AddressService} from "@ncnp-mono/utils";
import type {Address, AddressRequest} from "@ncnp-mono/generics";
import { Observable } from "@bakerware/vue-utils";
import type { VForm } from "@bakerware/vue-utils";
import {AddressRules} from "../config/address-rules";
import {ConfirmDialog} from "@ncnp-mono/components";

@Component({
    template: html
})
export class OrganisationAddressEditDialog extends IsDialogMixin {

    @Inject()
    addressService!: AddressService;

    @Prop()
    address!: Address;

    @Ref('form')
    form!: VForm;

    public result = new Observable<Address | null>();
    public request: AddressRequest = {};
    public rules = AddressRules;

    public created(): void {
        this.request = {
            street: this.address.street,
            number: this.address.number,
            zipCode: this.address.zipCode,
            city: this.address.city,
            active: this.address.active,
            addressType: this.address.addressType,
        };
    }

    remove(): void {
        this.$vueDialogs.open(ConfirmDialog, {
            propsData: {
                message: 'U staat op het punt om dit adres te verwijderen',
            },
            dialogSettings: {
                maxWidth: '728px',
            },
        }).onConfirm.observe(this.removeAddress);

        // ModalBus.$emit('open:modal', {
        //     component: ConfirmDialog,
        //     props: {
        //         message: 'U staat op het punt om dit adres te verwijderen',
        //         onConfirm: async () => {
        //             try {
        //                 await this.addressService.deleteAddress(
        //                     this.address.id
        //                 );
        //
        //                 this.result.emit(null);
        //
        //                 this.$snackbar.success('Verwijderen adres gelukt');
        //                 this.activeDialog.close();
        //             } catch (e) {
        //                 this.$snackbar.danger(
        //                     'Verwijderen adres mislukt, probeer opnieuw'
        //                 );
        //             }
        //         },
        //     },
        // });
    }

    async submit(): Promise<void> {
        if (!this.form.validate()) {
            return;
        }

        this.request.active = true;

        try {
            const address = await this.addressService.updateAddress(
                this.address.id,
                this.request
            );

            this.result.emit(address);

            this.$snackbar.success('Wijzigen adres gelukt');
            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.danger('Wijzigen adres mislukt');
        }
    }

    private async removeAddress(): Promise<void> {
        try {
            await this.addressService.deleteAddress(this.address.id);

            this.result.emit(null);

            this.$snackbar.success('Verwijderen adres gelukt');
            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.danger('Verwijderen adres mislukt, probeer opnieuw');
        }
    }
}
