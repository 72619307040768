import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { Contact, ContactRequest } from '@ncnp-mono/generics';
import { ContactTaxOfficeView } from '@ncnp-mono/generics';

@Register()
export class ContactService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public getContacts(): Promise<ContactTaxOfficeView[]> {
        return this.httpService.get(`/contacts`);
    }

    public updateContact(contactId: number, contactRequest: ContactRequest): Promise<Contact> {
        return this.httpService.put(`api/v1/contacts/${contactId}`, contactRequest);
    }

    public deleteContact(contactId: number): Promise<Contact> {
        return this.httpService.delete(`api/v1/contacts/${contactId}`);
    }
}
