import { RouteConfigSingleView } from "vue-router/types/router";
import { DashboardOverviewComponent } from "./pages/overview/dashboard-overview.component";

export const DashboardRoutingModule: RouteConfigSingleView[] = [
    {
        path: 'dashboard',
        name: 'dashboard',
        component: DashboardOverviewComponent,
        meta: {
            requiresAuth: true,
        },
    },
];
