import { Observable, Register } from '@bakerware/vue-utils';
import { Breadcrumb } from '@ncnp-mono/generics';

@Register()
export class BreadcrumbService {
    public breadcrumbs$: Observable<Breadcrumb[]>;
    private breadcrumbs: Breadcrumb[] = [];

    public constructor() {
        this.breadcrumbs$ = new Observable<Breadcrumb[]>();
    }

    public updateBreadcrumbs(crumbs: Breadcrumb[]): void {
        this.breadcrumbs = crumbs;
        this.breadcrumbs$.emit(this.breadcrumbs);
    }

    public updateBreadcrumbResolver(title: string): void {
        const breadcrumbs = this.breadcrumbs.map(breadcrumb =>
            breadcrumb.type === 'resolver'
                ? {
                    ...breadcrumb,
                    text: title,
                }
                : breadcrumb
        );

        this.breadcrumbs$.emit(breadcrumbs);
    }
}
