import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { TaxOfficeApi, TaxOfficeApiRequest, TaxOfficeApiSettings } from '@ncnp-mono/generics';

@Register()
export class TaxOfficeApiService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public getTaxOfficeApis(taxOfficeId: number): Promise<TaxOfficeApi[]> {
        return this.httpService.get(`api/v1/tax-offices/${taxOfficeId}/api-tokens`);
    }

    public createTaxOfficeApi(taxOfficeId: number, taxOfficeApiRequest: TaxOfficeApiRequest): Promise<TaxOfficeApi> {
        return this.httpService.post(`api/v1/tax-offices/${taxOfficeId}/api-tokens`, taxOfficeApiRequest);
    }

    public getDefaultTaxOfficeApiSettings(type: string): Promise<TaxOfficeApiSettings[]> {
        return this.httpService.get(`api/v1/tax-office-apis/default/${type}`);
    }

    public updateTaxOfficeApi(taxOfficeApiId: number, taxOfficeApiRequest: TaxOfficeApiRequest): Promise<TaxOfficeApi> {
        return this.httpService.put(`api/v1/tax-office-apis/${taxOfficeApiId}`, taxOfficeApiRequest);
    }
}
