import { addWeeks, format, startOfWeek } from 'date-fns';
import { dutchDateFormat } from '@bakerware/vue-validation-rules';

export function getMonths(): any[] {
    return [
        {
            value: 1,
            description: 'Januari', 
        },
        {
            value: 2,
            description: 'Februari', 
        },
        {
            value: 3,
            description: 'Maart', 
        },
        {
            value: 4,
            description: 'April', 
        },
        {
            value: 5,
            description: 'Mei', 
        },
        {
            value: 6,
            description: 'juni', 
        },
        {
            value: 7,
            description: 'Juli', 
        },
        {
            value: 8,
            description: 'Augustus', 
        },
        {
            value: 9,
            description: 'September', 
        },
        {
            value: 10,
            description: 'Oktober', 
        },
        {
            value: 11,
            description: 'November', 
        },
        {
            value: 12,
            description: 'December', 
        },
    ];
}

export function getYears(startYear: number): number[] {
    const currentYear = new Date().getFullYear() + 1;
    const years: number[] = [];
    while (startYear <= currentYear) {
        years.push(startYear++);
    }
    return years.reverse();
}

export function parseDate(date: string): string {
    if (!date) return '';

    const [ day, month, year, ] = date.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}

export function formatDate(date: any, dateFormat = 'DD-MM-YYYY', options?: { locale?: Object }) {
    if (!date) return null;

    if (date instanceof Date) {
        return format(date, dateFormat, options);
    } else if (typeof date === 'string') {
        if (dutchDateFormat(date) === true) {
            return formatDateString(date);
        } else {
            return format(new Date(date), dateFormat, options);
        }
    } else {
        return null;
    }
}

export function eachWeekOfInterval(dirtyInterval: any, options: any) {
    if (arguments.length < 1) {
        throw new TypeError(`1 argument required, but only ${arguments.length} present`);
    }

    const interval = dirtyInterval || {
    };
    const startDate = new Date(interval.start);
    const endDate = new Date(interval.end);

    let endTime = endDate.getTime();

    // Throw an exception if start date is after end date or if any date is `Invalid Date`
    if (!(startDate.getTime() <= endTime)) {
        throw new RangeError('Invalid interval');
    }

    const startDateWeek = startOfWeek(startDate, options);
    const endDateWeek = startOfWeek(endDate, options);

    // Some timezones switch DST at midnight, making start of day unreliable in these timezones, 3pm is a safe bet
    startDateWeek.setHours(15);
    endDateWeek.setHours(15);

    endTime = endDateWeek.getTime();

    const weeks = [];

    let currentWeek = startDateWeek;

    while (currentWeek.getTime() <= endTime) {
        currentWeek.setHours(0);
        weeks.push(new Date(currentWeek));
        currentWeek = addWeeks(currentWeek, 1);
        currentWeek.setHours(15);
    }

    return weeks;
}

function formatDateString(dateString: string) {
    const [ day, month, year, ] = dateString.split('-');
    return `${year}-${month}-${day}`;
}
