import { IsDialogMixin } from '@bakerware/vue-dialogs';
import html from './change-password.dialog.html';
import Component from 'vue-class-component';
import { email, hasNoScriptTags, max, password, passwordEqual, required } from '@bakerware/vue-validation-rules';
import { Inject } from 'vue-property-decorator';
import { UserService } from '@ncnp-mono/utils';
import { UpdatePasswordRequest } from '@ncnp-mono/generics';

@Component({
    template: html,
})
export class ChangePasswordDialog extends IsDialogMixin {

    @Inject()
    userService!: UserService;

    public rules = {
        name: [ required, max, hasNoScriptTags, ],
        email: [ required, email, ],
        newPassword: [ password, required, ],
        confirmPassword: [ password, required, ],
    };

    public formValid = false;

    public newPassword: string = '';
    public confirmPassword: string = '';
    public showNewPassword: boolean = false;
    public showConfirmPassword: boolean = false;

    public validatePassword(): void {
        this.rules.confirmPassword = [
            password,
            required,
            (v: unknown) => passwordEqual(v, this.newPassword),
        ];
    }

    public async save() {
        if (this.formValid) {
            try {
                const updatePasswordRequest: UpdatePasswordRequest = {
                    password: this.newPassword,
                    password_confirmation: this.confirmPassword,
                };

                await this.userService.updatePassword(
                    this.$auth.user?.id,
                    updatePasswordRequest
                );

                this.$snackbar.success('Uw wachtwoord is aangepast');
                this.activeDialog.close();

                await this.$router.push({
                    name: 'login',
                    query: {
                        message: 'Uw wachtwoord is aangepast.',
                    },
                });
            } catch (exception) {
                this.$snackbar.danger('Wachtwoord aanpassen mislukt, probeer opnieuw');
            }
        }
    }
}
