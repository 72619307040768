import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { serialize } from 'object-to-formdata';
import { Dossier,
    DossierActionResponse,
    DossierFileUploadRequest,
    DossierRequest,
    ObjectToFormDataOptions } from '@ncnp-mono/generics';
import fileDownload from 'js-file-download';

@Register()
export class DossierService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public getDossier(dossierId: string): Promise<Dossier> {
        return this.httpService.get(`api/v1/dossiers/${dossierId}`);
    }

    public createDossier(dossierRequest: DossierRequest): Promise<DossierActionResponse> {
        return this.httpService.upload(`api/v1/dossiers`, serialize(dossierRequest, ObjectToFormDataOptions));
    }

    public createDossiersBulk(dossierRequest: Partial<DossierRequest>): Promise<boolean> {
        return this.httpService.upload(`api/v1/dossiers/bulk`, serialize(dossierRequest, ObjectToFormDataOptions));
    }

    public updateDossier(dossierId: string, dossierRequest: DossierRequest): Promise<DossierActionResponse> {
        return this.httpService.put(`api/v1/dossiers/${dossierId}`, serialize(dossierRequest, ObjectToFormDataOptions));
    }

    public uploadDossierFile(dossierId: string, dossierFileUploadRequest: DossierFileUploadRequest): Promise<boolean> {
        return this.httpService.upload(`api/v1/dossiers/${dossierId}/aanvullen`, serialize(dossierFileUploadRequest, ObjectToFormDataOptions));
    }

    public downloadDeliveryNotification(actionId: string, fileName: string) {
        return this.httpService.download<Blob>(`api/v1/dossiers/ontvangstbevestiging/${actionId}`, 'GET', 'application/pdf')
            .then((blob) => {
                fileDownload(blob, fileName, 'application/pdf');
            });
    }
}
