import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { Address, AddressRequest } from '@ncnp-mono/generics';


@Register()
export class TaxOfficeAddressService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public createTaxOfficeAddress(taxOfficeId: string, addressRequest: AddressRequest): Promise<Address> {
        return this.httpService.post(`api/v1/tax-offices/${taxOfficeId}/addresses`, addressRequest);
    }
}
