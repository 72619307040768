import { DataTableHeader } from 'vuetify';

export const ClaimsOverviewHeaders: DataTableHeader[] = [
    {
        text: 'Adres',
        value: 'address',
        sortable: false,
    },
    {
        text: 'Postcode',
        value: 'zipCode',
        sortable: false,
    },
    {
        text: 'Plaats',
        value: 'city',
        sortable: false,
    },
    {
        text: 'WOZ-objectnummer',
        value: 'objectNumber',
        sortable: false,
    },
    {
        text: 'Reden bezwaar',
        value: 'reasonObjection',
        sortable: false,
    },
    {
        text: 'Status',
        value: 'status',
        sortable: false,
    },
    {
        text: 'Reden indien afgewezen',
        value: 'reasonNotAuthorized',
        sortable: false,
    },
    {
        text: 'Documenten',
        value: 'downloadDocument',
        sortable: false,
    }
];
