import { DataTableHeader } from 'vuetify';

export const DossierFilesOverviewHeaders: DataTableHeader[] = [
    {
        text: 'Bestandsnaam',
        value: 'fileName',
        sortable: false,
    },
    {
        text: 'Toelichting',
        value: 'comment',
        sortable: false,
    },
    {
        text: 'Download',
        value: 'download',
        sortable: false,
    },
    {
        text: 'Uploaddatum',
        value: 'createdAt',
        sortable: false,
    },
];
