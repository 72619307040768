import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import fileDownload from 'js-file-download';
import { BulkAuthorizeClaimsRequest,
    BulkAuthorizeClaimsResponse,
    Claim,
    RejectClaimRequest,
    RevokeClaimRequest,
    StateClaimRequest } from '@ncnp-mono/generics';

@Register()
export class ClaimService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public updateClaim(claimId: number, updateClaimRequest: RejectClaimRequest | RevokeClaimRequest | StateClaimRequest): Promise<Claim> {
        return this.httpService.put(`api/v1/claims/${claimId}`, updateClaimRequest);
    }

    public bulkAuthorizeClaims(dossierId: string, bulkAuthorizeClaimsRequest: BulkAuthorizeClaimsRequest): Promise<BulkAuthorizeClaimsResponse> {
        return this.httpService.put(`api/v1/dossiers/${dossierId}/bulk-authorize-claims`, bulkAuthorizeClaimsRequest);
    }

    public downloadTaxReport(claimId: number) {
        return this.httpService.download<Blob>(`api/v1/claims/${claimId}/tax-report`, 'GET', 'application/pdf')
            .then((blob) => {
                fileDownload(blob, 'Taxatieverslag', 'application/pdf');
            });
    }
}
