import Component from 'vue-class-component';
import { Inject, Prop, Ref } from 'vue-property-decorator';
import html from './tax-office-address-create.dialog.html';
import { IsDialogMixin } from "@bakerware/vue-dialogs";
import { Address, AddressRequest, AddressType } from "@ncnp-mono/generics";
import { AddressService, AddressTypeService, TaxOfficeAddressService } from "@ncnp-mono/utils";
import {Observable} from "@bakerware/vue-utils";
import type {VForm} from "@bakerware/vue-utils";
import { AddressRules } from "../../../config/address-rules";

@Component({
    template: html
})
export class TaxOfficeAddressCreateDialog extends IsDialogMixin {

    @Inject()
    taxOfficeAddressService!: TaxOfficeAddressService;

    @Inject()
    addressService!: AddressService;

    @Inject()
    addressTypeService!: AddressTypeService;

    @Ref('form')
    form!: VForm;

    @Prop()
    taxOfficeId!: number;

    @Prop()
    addresses!: Address[];

    public result = new Observable<Address>();
    public request: AddressRequest = {}
    private types: AddressType[] = [];
    public rules = AddressRules;

    get unusedAddressTypes(): AddressType[] {
        return this.types.filter(type => !this.addresses.some(address => address.addressType === type.code));
    }

    mounted(): void {
        this.getAddressTypes();
    }

    async submit(): Promise<void> {
        if (!this.form.validate()) {
            return;
        }

        this.request.active = true;

        try {
            const address = await this.taxOfficeAddressService.createTaxOfficeAddress(
                this.taxOfficeId.toString(),
                this.request
            );

            this.result.emit(address);

            this.$snackbar.success('Aanmaken adres gelukt');
            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.danger('Aanmaken adres mislukt');
        }
    }

    async getAddressTypes(): Promise<void> {
        try {
            this.types = await this.addressTypeService.getAddressTypes();
        } catch (e) {
            this.$snackbar.success('Ophalen van adres types is niet gelukt');
        }
    }
}
