import { DataTableHeader } from 'vuetify';

export const OrganisationsOverviewHeaders: DataTableHeader[] = [
    {
        text: 'Naam',
        value: 'name',
    },
    {
        text: 'KVK',
        value: 'kvk',
        sortable: false,
    },
    {
        text: 'Vestigingsnummer',
        value: 'branchNumber',
        sortable: false,
    },
    {
        text: 'Bezoekadres',
        value: 'visitAddress',
        sortable: false,
    },
    {
        text: 'Actief',
        value: 'active',
        sortable: false,
    },
    {
        text: 'Accounts',
        value: 'numberAccounts',
        sortable: false,
    },
    {
        text: 'Toegang tot',
        value: 'accessToTaxOffice',
        sortable: false,
    },
];
