import { Register } from '@bakerware/vue-utils';
import { HttpService } from '@bakerware/http';
import { UpdatePasswordRequest, User, UserRequest } from '@ncnp-mono/generics';

@Register()
export class UserService {
    public constructor(protected readonly httpService: HttpService) {
    }

    public updateUser(userId: number | undefined, userRequest: UserRequest): Promise<User> {
        return this.httpService.put(`api/v1/users/${userId}`, userRequest);
    }

    public deleteUser(userId: number): Promise<void> {
        return this.httpService.delete(`api/v1/users/${userId}`);
    }

    public updatePassword(userId: number | undefined, updatePasswordRequest: UpdatePasswordRequest): Promise<void> {
        return this.httpService.put(`api/v1/users/${userId}/update-password`, updatePasswordRequest);
    }

    public checkEmailExists(email: string | undefined): Promise<boolean> {
        return this.httpService.get(`api/v1/users/check-email-exists?email=${email}`);
    }

    public resetPassword(userId: number): Promise<void> {
        return this.httpService.post(`api/v1/users/${userId}/mail-password-reset`, null);
    }

    public sendActivationMail(userId: number): Promise<void> {
        return this.httpService.post(`api/v1/users/${userId}/mail-activation`, null);
    }
}
